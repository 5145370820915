<template>
  <FormKit :name v-model="startDate" :validation :label :help>
    <template #inner>
      <formkit-input-wrapper>
        <vue-date-picker
          :enable-time-picker="false"
          :clearable="false"
          :min-date="new Date()"
          :disabled
          :class="{ 'opacity-50': disabled }"
          position="left"
          :month-change-on-scroll="false"
          auto-apply
          v-model="startDate" />
      </formkit-input-wrapper>
    </template>
  </FormKit>
</template>

<script setup lang="ts">
import VueDatePicker from "@vuepic/vue-datepicker"
import FormkitInputWrapper from "/js/components/utilities/FormFields/FormkitInputWrapper.vue"

withDefaults(
  defineProps<{
    name?: string
    label?: string
    validation?: string
    help?: string
    disabled?: boolean
  }>(),
  {
    name: "start_date",
    label: "Start Date",
  }
)

const startDate = defineModel<Date | null>()
</script>

<script lang="ts">
export default {
  name: "DateTimeComponentField",
}
</script>
