<template>
  <div class="card bg-primary flex flex-col gap-2 border-2 border-gray-100 p-4">
    <div class="flex items-center justify-center rounded-lg p-6 text-white" :class="bg">
      <component :is="icons[blockType]" class="h-12 w-12" />
    </div>
    <h5 class="text-primary font-medium leading-none">{{ title }}</h5>
    <div class="text-secondary text-sm el-description">{{ description }}</div>
  </div>
</template>

<script setup lang="ts">
import type { WidgetType } from "/js/components/BlockBuilder/Widget"
import CommunityHeadingIcon from "/js/components/icons/CommunityHeadingIcon.vue"
import MultiUserFillIcon from "/js/components/icons/MultiUserFillIcon.vue"
import BadgeFillIcon from "/js/components/icons/BadgeFillIcon.vue"
import TextFillIcon from "/js/components/icons/TextFillIcon.vue"
import AttachmentFillIcon from "/js/components/icons/AttachmentFillIcon.vue"
import CalendarFillIcon from "/js/components/icons/CalendarFillIcon.vue"
import DashboardFillIcon from "/js/components/icons/DashboardFillIcon.vue"
import { QueueListIcon, ViewColumnsIcon, PhotoIcon, LinkIcon } from "@heroicons/vue/24/solid"
import BookFillIcon from "/js/components/icons/BookFillIcon.vue"
import MessageIcon from "/js/components/icons/MessageIcon.vue"

defineProps<{
  title: string
  description: string
  bg: string
  blockType: WidgetType
}>()

const icons: Record<WidgetType, any> = {
  HeaderWidget: CommunityHeadingIcon,
  MembersWidget: MultiUserFillIcon,
  FeaturedProductsWidget: BadgeFillIcon,
  RichTextWidget: TextFillIcon,
  ContentWidget: AttachmentFillIcon,
  EventWidget: CalendarFillIcon,
  FeedWidget: DashboardFillIcon,
  InstructorsWidget: MultiUserFillIcon,
  AccordionWidget: QueueListIcon,
  CurriculumWidget: BookFillIcon,
  ChatRoomWidget: MessageIcon,
  ColumnsWidget: ViewColumnsIcon,
  PhotoWidget: PhotoIcon,
  LinksWidget: LinkIcon
}

</script>

<script lang="ts">
export default {
  name: "BlockCard",
}
</script>
