<template>
  <div class="flex flex-col">
    <div class="relative">
      <gradient-image
        class="aspect-[21/6] max-h-96 w-full"
        :id="community?.id"
        :url="community?.cover_url" />
      <div class="absolute inset-0">
        <slot name="gradient-overlay"></slot>
      </div>
    </div>

    <div class="main-content-inset relative -mt-10 flex flex-col gap-4 lg:flex-row">
      <div class="flex flex-1 flex-col items-stretch gap-4 overflow-x-auto lg:w-0">
        <div class="card padding flex gap-4">
          <slot name="left-actionbar" />
          <router-link
            v-if="canEdit"
            :to="{ name: settingsRouteNames.registrationEdit }"
            class="btn btn-md btn-primary">
            Edit Registration Page
          </router-link>
          <slot name="right-actionbar" />
        </div>

        <block-widget-card v-for="element in mainItems" :element :key="element.id" />
      </div>
    </div>

    <learning-hub-overview-empty-card
      v-if="!queryWidgets?.length && !isLoading"
      class="mx-auto py-24" />
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue"
import BlockWidgetCard from "/js/components/BlockBuilder/Cards/BlockWidgetCard.vue"
import { type BlockWidget, makeBlockWidgets } from "/js/components/BlockBuilder/Widget"
import { useQuery } from "@tanstack/vue-query"
import { WidgetsApi } from "/js/services/WidgetsApi"
import { useGate } from "/js/composables/useGate"
import GradientImage from "/js/components/GradientImage.vue"
import LearningHubOverviewEmptyCard from "/js/components/LearningHubs/Overview/LearningHubOverviewEmptyCard.vue"
import type { Community } from "/js/models/Community"
import { settingsRouteNames } from "/js/router/AdminRoutes"

defineProps<{
  community?: Community
}>()

const { communityGateStatus } = useGate()

const mainItems = ref<BlockWidget[]>([])

const canEdit = computed(() => communityGateStatus("community_edit") === "authorized")

const { data: queryWidgets, isLoading } = useQuery({
  queryKey: ["community_registration_widgets"],
  queryFn: async () => {
    const widgets = await WidgetsApi.getCommunityWidgets("community_registration")
    mainItems.value = makeBlockWidgets(widgets, "main")
    return widgets
  },
})
</script>

<script lang="ts">
export default {
  name: "CommunityRegistrationOverview",
}
</script>
