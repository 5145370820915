import { computed } from "vue"
import type { CommunityRole, ProductRole } from "/js/services/permissionPolicyService"
import { UsersApi } from "/js/services/UsersApi"
import { PlansApi } from "/js/services/PlansApi"
import { useSimpleQuery } from "/js/composables/useSimpleQuery"
import { PlatformPlansApi } from "/js/services/PlatformPlansApi"
import { usePlatformSubscription } from "/js/composables/useGatePlatformSubscription"
import { useMemberSubscription } from "/js/composables/useGateMemberSubscription"

// const groups = ref<UserGroup[]>([])

export const CommunityGroupAbilities = [
  "community_read",
  "community_edit",
  "community_billing",
] as const

export const ProductGroupAbilities = ["product_read", "content_manage", "product_edit"] as const

export const GroupAbilities = [...CommunityGroupAbilities, ...ProductGroupAbilities] as const
export type GroupAbility = (typeof GroupAbilities)[number]
export type ProductAbility = (typeof ProductGroupAbilities)[number]
export type CommunityAbility = (typeof CommunityGroupAbilities)[number]

type GroupRole = CommunityRole | ProductRole

const GroupTypeAbilities: Record<GroupRole, GroupAbility[]> = {
  community_owners: ["community_read", "community_edit", "community_billing"],
  community_admins: ["community_read", "community_edit"],
  community_members: ["community_read"],
  product_managers: ["product_read", "content_manage", "product_edit"],
  product_instructors: ["product_read", "content_manage"],
  product_members: ["product_read"],
}

// Gate Access to the community or product
export type GateStatus =
  | "loading"
  | "failed"
  | "authorized"
  | "requires_permission"
  | "requires_subscription"

const {
  data: groups,
  isLoading: isLoadingGroups,
  isSuccess: isSuccessGroups,
  fetchData: fetchUserGroups,
} = useSimpleQuery(async () => {
  return await UsersApi.getUserGroups()
})

const memberSubscriptionsEnabled = window.APP_CONFIG.MEMBER_SUBSCRIPTION

const { fetchData: fetchPlatformSubscription, platformAccessStatus } = usePlatformSubscription()
const {
  fetchData: fetchMemberSubscription,
  fetchTokens: fetchMemberTokens,
  tokenAccessStatus,
  productSubscriptionRequired,
  hasTokens,
  memberAccessStatus,
} = useMemberSubscription()

const isSignedIn = computed(() => !!window.APP_CONFIG.USER_ID)

export const useGate = () => {
  const fetchGate = async () => {
    if (!isSignedIn.value) return

    return await Promise.allSettled([
      fetchUserGroups(),
      fetchMemberSubscription(),
      fetchMemberTokens(),
      fetchPlatformSubscription(),
    ])
  }

  const isLoading = computed(
    () =>
      isLoadingGroups.value ||
      tokenAccessStatus.value === "loading" ||
      memberAccessStatus.value === "loading" ||
      platformAccessStatus.value === "loading"
  )

  const isFailed = computed(
    () =>
      !isLoading.value &&
      (!isSuccessGroups.value ||
        tokenAccessStatus.value === "disabled" ||
        memberAccessStatus.value === "disabled" ||
        platformAccessStatus.value === "disabled")
  )

  const fetchGateIfNeeded = async () => {
    if (isLoading.value || isFailed.value) {
      return await fetchGate()
    }
    return true
  }

  // a platform subscription or trial is required for anyone to access the platform
  const hasPlatformAccess = computed(() => {
    return platformAccessStatus.value === "enabled"
  })

  const hasCommunityAdminAccess = computed(() => {
    if (!groups.value) return false
    return groups.value.some(
      (group) => group.group_type === "community_admins" || group.group_type === "community_owners"
    )
  })

  const hasCommunityOwnerAccess = computed(() => {
    if (!groups.value) return false
    return groups.value.some((group) => group.group_type === "community_owners")
  })

  const hasAnyProductManagerAccess = computed(() => {
    // todo: might need to differentiate more between product managers and product instructors
    if (!groups.value) return false
    return groups.value.some(
      (group) =>
        group.group_type === "product_managers" || group.group_type === "product_instructors"
    )
  })

  // only checks for ability
  const hasProductAbility = (productId: string, ability: GroupAbility) => {
    if (!groups.value) return false
    return groups.value.some(
      (group) =>
        group.group_type !== "custom" &&
        group.product_id === productId &&
        GroupTypeAbilities[group.group_type].includes(ability)
    )
  }

  // only checks for ability
  const hasCommunityAbility = (ability: CommunityAbility) => {
    if (!groups.value) return false
    return groups.value.some(
      (group) =>
        group.group_type !== "custom" && GroupTypeAbilities[group.group_type].includes(ability)
    )
  }

  const communityGateStatus = (ability: CommunityAbility): GateStatus => {
    if (isFailed.value) return "failed"
    if (isLoading.value) return "loading"
    if (!groups.value || groups.value.length === 0) return "requires_permission"
    if (hasCommunityAbility(ability)) {
      if (ability === "community_read") {
        if (hasCommunityAdminAccess.value) return "authorized"
        if (hasAnyProductManagerAccess.value) return "authorized"
        if (memberSubscriptionsEnabled) {
          const hasSubscription = memberAccessStatus.value === "enabled"
          if (hasSubscription || hasTokens.value) return "authorized"
          return "requires_subscription"
        } else {
          return "authorized"
        }
      } else {
        return "authorized"
      }
    } else {
      return "requires_permission"
    }
  }

  const productGateStatus = (productId: string, ability: ProductAbility): GateStatus => {
    if (isFailed.value) return "failed"
    if (isLoading.value) return "loading"

    if (!groups.value || groups.value.length === 0) return "requires_permission"
    if (hasCommunityAdminAccess.value) return "authorized"

    if (!hasProductAbility(productId, ability)) return "requires_permission"
    else {
      if (
        hasProductAbility(productId, "content_manage") ||
        hasProductAbility(productId, "product_edit")
      )
        return "authorized"
    }

    if (memberSubscriptionsEnabled) {
      if (productSubscriptionRequired(productId)) return "requires_subscription"
    }

    return "authorized"
  }

  return {
    communityGateStatus,
    productGateStatus,
    isFailed,
    isLoading,
    fetchGateIfNeeded,
    hasPlatformAccess,
    hasCommunityAbility,
    hasCommunityOwnerAccess,
    hasCommunityAdminAccess,
    hasAnyProductManagerAccess,
  }
}
