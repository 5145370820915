<template>
  <div class="flex flex-col items-center justify-center gap-2 py-24">
    <button v-if="action" type="button" @click="onAction">
      <slot>
        <!-- Empty Icon -->
      </slot>
    </button>
    <div v-else>
      <slot>
        <!-- Empty Icon -->
      </slot>
    </div>

    <div class="text-l pt-6 font-medium whitespace-pre-line text-center">
      <slot name="title">{{ props.title }}</slot>
    </div>

    <div v-if="props.subtitle" class="text-secondary py-2 text-center whitespace-pre-line">
      <slot name="subtitle">{{ props.subtitle }}</slot>
    </div>

    <div class="py-6">
      <slot name="action">
        <button type="button" v-if="props.action" class="btn btn-primary" @click="onAction">
          {{ props.action }}
        </button>
      </slot>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  title?: string
  subtitle?: string
  action?: string
}>()

const emit = defineEmits<{
  onAction: []
}>()

const onAction = () => {
  emit("onAction")
}
</script>

<script lang="ts">
export default {
  name: "EmptyView",
}
</script>
