import { z } from "zod"

const baseZInterviewField = z.object({
  id: z.string(),
  name: z.string(),
})

export type InterviewField = z.infer<typeof baseZInterviewField> & {
  subfields?: InterviewField[]
}

export const ZInterviewField: z.ZodType<InterviewField> = baseZInterviewField.extend({
  subfields: z.lazy(() => ZInterviewField.array().optional()),
})

export const InterviewQuestionTypes = ["background", "situational", "technical"] as const
export const ZInterviewQuestion = z.object({
  id: z.string(),
  question_text: z.string(),
  question_type: z.enum(InterviewQuestionTypes),
  audio_url: z.string().nullable()
})

export const ZMostUsedWord = z.object({
  word: z.string(),
  count: z.number(),
  synonyms: z.string().array().optional(),
})

export const ZSentenceAnalysis = z.object({
  experience: z.string().array().optional(),
  lessons_learned: z.string().array().optional(),
  skills: z.string().array().optional(),
  examples: z.string().array().optional(),
  results: z.string().array().optional(),
})

export const ZInterviewAnswerQualityResponse = z.object({
  id: z.string(),
  depth_specificity: z.string().optional(),
  technical_accuracy: z.string().optional(),
  relevance_completeness: z.string().optional(),
  star_framework_analysis: z.string().optional(),
  communication_style: z.string().optional(),
  grammar_and_language: z.string().optional(),
  job_related_terms: z.string().array().optional(),
  most_used_words: ZMostUsedWord.array().optional(),
  sentences: ZSentenceAnalysis.optional(),
})

export const ZPunctuationResponse = z.object({
  id: z.string(),
  parsed_text: z.string(),
})

export type InterviewQuestion = z.infer<typeof ZInterviewQuestion>
export type InterviewQuestionType = (typeof InterviewQuestionTypes)[number]
export type InterviewAnswerQualityResponse = z.infer<typeof ZInterviewAnswerQualityResponse>
export type MostUsedWord = z.infer<typeof ZMostUsedWord>
export type SentenceAnalysis = z.infer<typeof ZSentenceAnalysis>
export type PunctuationResponse = z.infer<typeof ZPunctuationResponse>

export const getBgColorByQuestionType = (type: InterviewQuestionType | undefined) => {
  switch (type) {
    case "background":
      return "bg-blue-100"
    case "situational":
      return "bg-yellow-100"
    case "technical":
      return "bg-green-100"
    case undefined:
      return "bg-gray-100"
  }
}

export const getTextColorByQuestionType = (type: InterviewQuestionType | undefined) => {
  switch (type) {
    case "background":
      return "text-blue-700"
    case "situational":
      return "text-yellow-700"
    case "technical":
      return "text-green-700"
    case undefined:
      return "text-gray-700"
  }
}
