<template>
  <right-modal v-if="!trigger" v-model="showModalRef" rightModalWidthClass="w-screen max-w-xl">
    <user-modal-content :userId v-if="userId" />
  </right-modal>
  <slot v-bind="{ showModal }"></slot>
</template>

<script setup lang="ts">
import RightModal from "/js/components/Modals/RightModal.vue"
import UserModalContent from "/js/components/Modals/UserModalContent.vue"
import { useRouteUserModal } from "/js/composables/useRouteUserModal"

const props = withDefaults(defineProps<{
  trigger?: boolean
  userId?: string
}>(), {
  trigger: true
})

const { openUserModal } = useRouteUserModal()

const showModalRef = defineModel<boolean>({ default: false })

const showModal = () => {
  // showModalRef.value = true
  if (props.userId) {
    openUserModal(props.userId)
  }
}
</script>

<script lang="ts">
export default {
  name: "UserModal",
}
</script>
