import { computed } from "vue"
import { useSimpleQuery } from "/js/composables/useSimpleQuery"
import { PlatformPlansApi } from "/js/services/PlatformPlansApi"

const platformSubscriptionEnabled = window.APP_CONFIG.PLATFORM_SUBSCRIPTION

const { data, isLoading, isSuccess, fetchData } = useSimpleQuery(async () => {
  if (platformSubscriptionEnabled) {
    return await PlatformPlansApi.getSubscription()
  } else {
    return null
  }
})

export type PlatformAccess = "loading" | "enabled" | "disabled"

export const usePlatformSubscription = () => {
  const platformAccessStatus = computed((): PlatformAccess => {
    if (!platformSubscriptionEnabled) return "enabled"
    if (isLoading.value) return "loading"
    if (!isSuccess.value) return "disabled"
    return !!data.value ? "enabled" : "disabled"
  })

  return {
    platformAccessStatus,
    fetchData,
  }
}
