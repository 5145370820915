<template>
  <global-search-field #default="{ openSearch }">
    <button @click="openSearch">
      <span class="flex flex-col items-center" v-tooltip="hideTitles ? 'Search' : undefined">
        <span class="rounded-lg p-2">
          <MagnifierIcon class="h-6 w-6" />
        </span>
        <span v-if="!hideTitles" class="text-xxs">Search</span>
      </span>
    </button>
  </global-search-field>
  <router-link :to="{ name: 'notifications' }" #default="{ href, navigate, isActive }">
    <span class="flex flex-col items-center" v-tooltip="hideTitles ? 'Notifications' : undefined">
      <span class="rounded-lg p-2" :class="{ 'bg-gray-300': isActive }">
        <BellIcon class="h-6 w-6" v-if="!hasNotifications" />
        <UnreadDotBellIcon class="h-6 w-6" v-else />
      </span>
      <span v-if="!hideTitles" class="text-xxs">Notifications</span>
    </span>
  </router-link>
  <router-link :to="{ name: 'assistant' }" #default="{ href, navigate, isActive }">
    <span class="flex flex-col items-center" v-tooltip="hideTitles ? 'Zixie Chat' : undefined">
      <span class="rounded-lg p-2" :class="{ 'bg-gray-300': isActive }">
        <WizardIcon class="h-6 w-6" />
      </span>
      <span v-if="!hideTitles" class="text-xxs">Zixie Chat</span>
    </span>
  </router-link>
  <router-link :to="{ name: 'profile' }" class="btn-zoom" v-tooltip="hideTitles ? 'Profile' : undefined">
    <round-avatar
      size="sm"
      :id="currentUser?.id"
      :verified="currentUser?.verified"
      :avatar-url="currentUser?.avatar_url"
      :text="initials(currentUser)" />
  </router-link>
</template>

<script setup lang="ts">
import RoundAvatar from "/js/components/RoundAvatar.vue"
import WizardIcon from "/js/components/icons/WizardIcon.vue"
import UnreadDotBellIcon from "/js/components/icons/UnreadDotBellIcon.vue"
import BellIcon from "/js/components/icons/BellIcon.vue"
import MagnifierIcon from "/js/components/icons/MagnifierIcon.vue"
import GlobalSearchField from "/js/components/GlobalSearch/GlobalSearchField.vue"
import { initials } from "/js/models/User"
import { useCurrentUserService } from "/js/services/useCurrentUserService"
import { storeToRefs } from "pinia"
import { useNotificationsStore } from "/js/services/NotificationsStore"
import { computed } from "vue"

const props = withDefaults(
  defineProps<{
    hideTitles?: boolean
  }>(),
  {
    hideTitles: false,
  }
)

const { data: currentUser } = useCurrentUserService().load()

const currentCommunityId = window.APP_CONFIG.COMMUNITY_ID

const { communitiesUnreadCount } = storeToRefs(useNotificationsStore())

const hasNotifications = computed(() => {
  if (currentCommunityId) {
    return communitiesUnreadCount.value[currentCommunityId] ?? 0 > 0
  } else {
    console.log("currentCommunityId is not set")
    return false
  }
})
</script>
