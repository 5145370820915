import { z } from "zod"
import { BlockCards, homeBlockCards } from "/js/components/BlockBuilder/BlockCardTypes"
import { widgetFormData } from "/js/components/BlockBuilder/WidgetFormData"
import type { BlockBuilderConfig } from "/js/components/BlockBuilder/BlockBuilderConfig"
import { ZNavigationResource } from "/js/components/NavigationResources/NavigationResourcesTypes"
import { computed } from "vue"

export const WidgetPlacements = ["main", "side"] as const
export const MembersWidgetMemberTypes = ["admin_member", "user_member", "all_member"] as const
export const ContentWidgetDisplayTypes = ["preview", "full"] as const
export const CarouselDisplayTypes = ["carousel", "list"] as const
export const ProductWidgetScopeTypes = ["product_overview", "product_faq"] as const
export const CommunityWidgetScopeTypes = ["community_home", "community_registration"] as const
export const WidgetScopeTypes = [...ProductWidgetScopeTypes, ...CommunityWidgetScopeTypes] as const
export const WidgetPageTypes = ["one_column", "two_column"] as const
export const RichTextWidgetUrlTypes = ["internal", "external"] as const
export const LinkWidgetItemUrlTypes = ["internal", "external"] as const
export const PhotoCardDisplayTypes = ["cover", "contain"] as const

export type RichTextWidgetUrlType = (typeof RichTextWidgetUrlTypes)[number]
export type LinkWidgetItemUrlType = (typeof LinkWidgetItemUrlTypes)[number]
export type PhotoCardDisplayType = (typeof PhotoCardDisplayTypes)[number]

export type WidgetPageCategory =
  | {
      type: "community"
    }
  | {
      type: "product"
      product_id: string
    }

export type WidgetPlacement = (typeof WidgetPlacements)[number]
export type MembersWidgetMemberType = (typeof MembersWidgetMemberTypes)[number]
export type CarouselDisplayType = (typeof CarouselDisplayTypes)[number]
export type ContentWidgetDisplayType = (typeof ContentWidgetDisplayTypes)[number]
export type ProductWidgetScopeType = (typeof ProductWidgetScopeTypes)[number]
export type CommunityWidgetScopeType = (typeof CommunityWidgetScopeTypes)[number]
export type WidgetScopeType = (typeof WidgetScopeTypes)[number]
export type WidgetPageType = (typeof WidgetPageTypes)[number] // rename this

const ZHasID = z.object({ id: z.string() })
const ZNestedAttributes = z.object({
  id: z.string().nullable().optional(),
  _destroy: z.boolean().nullable().optional(),
})

export const ColumnSizeClasses = ["3", "4", "6", "8", "9", "12"] as const
export type ColumnSizeClass = (typeof ColumnSizeClasses)[number]

export const ColumnOffsetSizes = ["0", "1", "2", "3", "4", "5"] as const
export type ColumnOffsetSize = (typeof ColumnOffsetSizes)[number]

export const WidgetCardHorizontalMarginSizes = ["sm", "md", "lg", "xl", "2xl", "3xl"] as const
export type WidgetCardHorizontalMarginSize = (typeof WidgetCardHorizontalMarginSizes)[number]

export const ZColumnSize = z.object({
  id: z.string(),
  size: z.enum(ColumnSizeClasses),
})

export type ColumnSize = z.infer<typeof ZColumnSize>

export const ZWidgetBaseConfig = z.object({
  disable_card: z.boolean().nullable().optional(),
  disable_padding: z.boolean().nullable().optional(),
  disable_title: z.boolean().nullable().optional(),
  enable_title_padding: z.boolean().nullable().optional(),
  card_horizontal_margin: z.enum(WidgetCardHorizontalMarginSizes).nullable().optional(),
})

export type WidgetBaseConfig = z.infer<typeof ZWidgetBaseConfig>

export const ZWidgetBase = z.object({
  id: z.string(),
  placement: z.enum(WidgetPlacements).nullable(),
  position: z.number(),
  updated_at: z.date(),
  parent_id: z.string().nullable(),
  title: z.string().nullable(),
  ...ZWidgetBaseConfig.shape,
})

// Header Widget Object / Params

export const ZHeaderWidgetBase = z.object({
  subtitle: z.string(),
  body: z.string(),
})

export const ZHeaderWidget = ZWidgetBase.merge(ZHeaderWidgetBase).extend({
  cover_url: z.string().url().nullable().optional(),
})

export const ZHeaderWidgetParams = ZWidgetBase.partial().merge(ZHeaderWidgetBase).extend({
  cover: z.string().nullable().optional(),
})

// Columns Widget Object / Params

export const ZColumnsWidgetBase = z.object({
  no_gap: z.boolean().nullable().optional(),
  column_sizes: ZColumnSize.array().nullable().optional(),
  carousel: z.boolean().nullable().optional(),
})

export const ZColumnsWidget = ZWidgetBase.merge(ZColumnsWidgetBase)

export const ZColumnsWidgetParams = ZWidgetBase.partial().merge(ZColumnsWidgetBase)

// Links Widget Object / Params

export const ZLinkWidgetItemAttributes = z.object({
  url_type: z.enum(LinkWidgetItemUrlTypes),
  label: z.string(),
  url: z.string().url().nullable().optional(),
  linkable_id: z.string().nullable().optional(),
  linkable_type: z.string().nullable().optional(),
  position: z.number().nullable().optional(),
  description: z.string().nullable().optional(),
})

export const ZLinkWidgetItemNestedAttributes = ZLinkWidgetItemAttributes.merge(ZNestedAttributes)

export const ZLinkWidgetItem = ZLinkWidgetItemAttributes.merge(ZHasID).extend({
  linkable: ZNavigationResource.nullable(),
})

export const ZLinksWidget = ZWidgetBase.extend({
  link_widget_items: ZLinkWidgetItem.array(),
})

export const ZLinksWidgetParams = ZWidgetBase.partial().extend({
  link_widget_items_attributes: ZLinkWidgetItemNestedAttributes.array(),
})

// Members Widget Object / Params

export const ZMembersWidgetBase = z.object({
  max_members: z.number().max(5).min(1),
  member_type: z.enum(MembersWidgetMemberTypes),
})

export const ZMembersWidget = ZWidgetBase.merge(ZMembersWidgetBase)

export const ZMembersWidgetParams = ZWidgetBase.partial().merge(ZMembersWidgetBase)

// Photo Widget Object / Params

export const ZPhotoWidget = ZWidgetBase.extend({
  cover_url: z.string().url().nullable().optional(),
  display_type: z.enum(PhotoCardDisplayTypes),
})

export const ZPhotoWidgetParams = ZWidgetBase.partial().extend({
  cover: z.string().nullable().optional(),
  display_type: z.enum(PhotoCardDisplayTypes),
})

// Instructors Widget Object / Params

export const ZInstructorsWidget = ZWidgetBase.extend({
  product_id: z.string(),
})

export const ZInstructorsWidgetParams = ZWidgetBase.partial()
  .extend({
    product_id: z.string().nullable().optional(),
  })
  .refine((data) => data.product_id !== undefined && data.product_id !== null, {
    message: "Product is required",
    path: ["product_id"],
  })

// Featured Products Widget Object / Params

export const ZFeaturedProductsWidgetBase = z.object({
  product_ids: z.string().array(),
})

export const ZFeaturedProductsWidget = ZWidgetBase.merge(ZFeaturedProductsWidgetBase)

export const ZFeaturedProductsWidgetParams = ZWidgetBase.partial().merge(
  ZFeaturedProductsWidgetBase
)

// Content Widget Object / Params

export const ZContentWidgetBase = z.object({
  display_type: z.enum(ContentWidgetDisplayTypes),
  editor_content_ids: z.string().array().optional().nullable(),
})

export const ZContentWidget = ZWidgetBase.merge(ZContentWidgetBase)

export const ZContentWidgetParams = ZWidgetBase.partial().merge(ZContentWidgetBase)

// Chat Room Widget Object / Params

export const ZChatRoomWidgetBase = z.object({
  chat_room_ids: z.string().array(),
  product_id: z.string().nullable(),
})

export const ZChatRoomWidget = ZWidgetBase.merge(ZChatRoomWidgetBase)

export const ZChatRoomWidgetParams = ZWidgetBase.partial().merge(ZChatRoomWidgetBase)

// Accordion Widget Object / Params

export const ZAccordionWidgetItem = z.object({
  id: z.string(),
  title: z.string(),
  content: z.string(),
})

export const ZAccordionWidgetBase = z.object({
  items: ZAccordionWidgetItem.array(),
})

export const ZAccordionWidget = ZWidgetBase.merge(ZAccordionWidgetBase)

export const ZAccordionWidgetParams = ZWidgetBase.partial().merge(ZAccordionWidgetBase)

// Event Widget Object / Params

export const ZEventWidgetBase = z.object({
  max_events: z.number().max(5).min(1),
  display_type: z.enum(CarouselDisplayTypes),
})

export const ZEventWidget = ZWidgetBase.merge(ZEventWidgetBase)

export const ZEventWidgetParams = ZWidgetBase.partial().merge(ZEventWidgetBase)

// Rich Text Widget Object / Params

export const ZRichTextWidgetBase = z.object({
  show_cta: z.boolean(),
  cta_label: z.string().nullable().optional(),
  cta_url: z.string().url().nullable().optional(),
  cta_url_type: z.enum(RichTextWidgetUrlTypes).nullable().optional(),
  cta_url_object: ZNavigationResource.nullable().optional(),
  cta_url_object_id: z.string().nullable().optional(),
  cta_url_object_type: z.string().nullable().optional(),
  body: z.any(),
})

export const ZRichTextWidget = ZWidgetBase.merge(ZRichTextWidgetBase)

export const ZRichTextWidgetParams = ZWidgetBase.partial().merge(ZRichTextWidgetBase)

// Curriculum Widget Object / Params

export const ZCurriculumWidget = ZWidgetBase.extend({
  product_id: z.string(),
})

export const ZCurriculumWidgetParams = ZWidgetBase.partial()
  .extend({
    product_id: z.string().nullable().optional(),
  })
  .refine((data) => data.product_id !== undefined && data.product_id !== null, {
    message: "Product is required",
    path: ["product_id"],
  })

// Feed Widget Object / Params

export const ZFeedWidgetBase = z.object({
  display_type: z.enum(CarouselDisplayTypes),
})

export const ZFeedWidget = ZWidgetBase.merge(ZFeedWidgetBase)

export const ZFeedWidgetParams = ZWidgetBase.partial().merge(ZFeedWidgetBase)

const HeaderWidgetType = z.literal("HeaderWidget")
const MembersWidgetType = z.literal("MembersWidget")
const FeaturedProductsWidgetType = z.literal("FeaturedProductsWidget")
const ContentWidgetType = z.literal("ContentWidget")
const EventWidgetType = z.literal("EventWidget")
const FeedWidgetType = z.literal("FeedWidget")
const RichTextWidgetType = z.literal("RichTextWidget")
const InstructorsWidgetType = z.literal("InstructorsWidget")
const AccordionWidgetType = z.literal("AccordionWidget")
const ChatRoomWidgetType = z.literal("ChatRoomWidget")
const CurriculumWidgetType = z.literal("CurriculumWidget")
const ColumnsWidgetType = z.literal("ColumnsWidget")
const LinksWidgetType = z.literal("LinksWidget")
const PhotoWidget = z.literal("PhotoWidget")

export const WidgetTypes = [
  HeaderWidgetType.value,
  MembersWidgetType.value,
  FeaturedProductsWidgetType.value,
  ContentWidgetType.value,
  EventWidgetType.value,
  FeedWidgetType.value,
  RichTextWidgetType.value,
  InstructorsWidgetType.value,
  AccordionWidgetType.value,
  ChatRoomWidgetType.value,
  CurriculumWidgetType.value,
  ColumnsWidgetType.value,
  LinksWidgetType.value,
  PhotoWidget.value,
] as const

export const WidgetModels = {
  HeaderWidget: { model: ZHeaderWidget, params: ZHeaderWidgetParams },
  MembersWidget: { model: ZMembersWidget, params: ZMembersWidgetParams },
  FeaturedProductsWidget: { model: ZFeaturedProductsWidget, params: ZFeaturedProductsWidgetParams },
  ContentWidget: { model: ZContentWidget, params: ZContentWidgetParams },
  EventWidget: { model: ZEventWidget, params: ZEventWidgetParams },
  FeedWidget: { model: ZFeedWidget, params: ZFeedWidgetParams },
  RichTextWidget: { model: ZRichTextWidget, params: ZRichTextWidgetParams },
  InstructorsWidget: { model: ZInstructorsWidget, params: ZInstructorsWidgetParams },
  AccordionWidget: { model: ZAccordionWidget, params: ZAccordionWidgetParams },
  ChatRoomWidget: { model: ZChatRoomWidget, params: ZChatRoomWidgetParams },
  CurriculumWidget: { model: ZCurriculumWidget, params: ZCurriculumWidgetParams },
  ColumnsWidget: { model: ZColumnsWidget, params: ZColumnsWidgetParams },
  LinksWidget: { model: ZLinksWidget, params: ZLinksWidgetParams },
  PhotoWidget: { model: ZPhotoWidget, params: ZPhotoWidgetParams },
} as const

export const ZWidget = z.union([
  z.object({ type: HeaderWidgetType, ...ZHeaderWidget.shape }),
  z.object({ type: MembersWidgetType, ...ZMembersWidget.shape }),
  z.object({ type: FeaturedProductsWidgetType, ...ZFeaturedProductsWidget.shape }),
  z.object({ type: ContentWidgetType, ...ZContentWidget.shape }),
  z.object({ type: EventWidgetType, ...ZEventWidget.shape }),
  z.object({ type: FeedWidgetType, ...ZFeedWidget.shape }),
  z.object({ type: RichTextWidgetType, ...ZRichTextWidget.shape }),
  z.object({ type: InstructorsWidgetType, ...ZInstructorsWidget.shape }),
  z.object({ type: AccordionWidgetType, ...ZAccordionWidget.shape }),
  z.object({ type: ChatRoomWidgetType, ...ZChatRoomWidget.shape }),
  z.object({ type: CurriculumWidgetType, ...ZCurriculumWidget.shape }),
  z.object({ type: ColumnsWidgetType, ...ZColumnsWidget.shape }),
  z.object({ type: LinksWidgetType, ...ZLinksWidget.shape }),
  z.object({ type: PhotoWidget, ...ZPhotoWidget.shape }),
])

export const ZWidgetParamsBase = z.object({
  placement: z.enum(WidgetPlacements).nullable(),
  position: z.number(),
  parent_id: z.string().nullable().optional(),
})

export const ZWidgetParams = z.union([
  z.object({
    type: HeaderWidgetType,
    ...ZWidgetParamsBase.shape,
    ...WidgetModels.HeaderWidget.params.shape,
  }),
  z.object({
    type: MembersWidgetType,
    ...ZWidgetParamsBase.shape,
    ...WidgetModels.MembersWidget.params.shape,
  }),
  z.object({
    type: FeaturedProductsWidgetType,
    ...ZWidgetParamsBase.shape,
    ...WidgetModels.FeaturedProductsWidget.params.shape,
  }),
  z.object({
    type: ContentWidgetType,
    ...ZWidgetParamsBase.shape,
    ...WidgetModels.ContentWidget.params.shape,
  }),
  z.object({
    type: EventWidgetType,
    ...ZWidgetParamsBase.shape,
    ...WidgetModels.EventWidget.params.shape,
  }),
  z.object({
    type: FeedWidgetType,
    ...ZWidgetParamsBase.shape,
    ...WidgetModels.FeedWidget.params.shape,
  }),
  z.object({
    type: RichTextWidgetType,
    ...ZWidgetParamsBase.shape,
    ...WidgetModels.RichTextWidget.params.shape,
  }),
  z.object({
    type: InstructorsWidgetType,
    ...ZWidgetParamsBase.shape,
    ...WidgetModels.InstructorsWidget.params._def.schema.shape,
  }),
  z.object({
    type: AccordionWidgetType,
    ...ZWidgetParamsBase.shape,
    ...WidgetModels.AccordionWidget.params.shape,
  }),
  z.object({
    type: ChatRoomWidgetType,
    ...ZWidgetParamsBase.shape,
    ...WidgetModels.ChatRoomWidget.params.shape,
  }),
  z.object({
    type: CurriculumWidgetType,
    ...ZWidgetParamsBase.shape,
    ...WidgetModels.CurriculumWidget.params._def.schema.shape,
  }),
  z.object({
    type: ColumnsWidgetType,
    ...ZWidgetParamsBase.shape,
    ...WidgetModels.ColumnsWidget.params.shape,
  }),
  z.object({
    type: LinksWidgetType,
    ...ZWidgetParamsBase.shape,
    ...WidgetModels.LinksWidget.params.shape,
  }),
  z.object({
    type: PhotoWidget,
    ...ZWidgetParamsBase.shape,
    ...WidgetModels.PhotoWidget.params.shape,
  }),
])

// Utility type to omit 'type' field from each member of the union
// type OmitType<T> = T extends any ? Omit<T, 'type'> : never;

// Create a new type by applying OmitType to each member of the WidgetParams union
// export type WidgetParamsWithoutType = OmitType<WidgetParams>;

export const ZBlockCardProps = z.object({
  title: z.string(),
  description: z.string(),
  bg: z.string(),
  blockType: z.enum(WidgetTypes),
})

export const ZBlockWidgetWidget = z.object({
  id: z.string(),
  type: z.literal("widget"),
  data: ZWidget,
})

export const ZBlockWidgetBlock = z.object({
  id: z.string(),
  type: z.literal("block"),
  data: ZBlockCardProps,
})

export const ZBlockWidget: z.ZodType = z.lazy(() =>
  z.union([ZBlockWidgetWithChildren, ZBlockWidgetBlock])
)

export const ZBlockWidgetWithChildren = ZBlockWidgetWidget.extend({
  children: z.array(ZBlockWidget),
  parent: ZWidget.optional(),
})

export type BlockWidgetWidget = z.infer<typeof ZBlockWidgetWidget>
export type BlockWidgetWithChildren = z.infer<typeof ZBlockWidgetWithChildren>

export const getWidgetType = (widget: BlockWidget): WidgetType => {
  if (widget.type === "widget") {
    return widget.data.type
  } else {
    return widget.data.blockType
  }
}

export const getWidgetId = (widget: BlockWidget): string => {
  if (widget.type === "widget") {
    return widget.data.id
  } else {
    return widget.data.id
  }
}

export const getWidgetTypeTitle = (widget: BlockWidget) => {
  return Object.values(BlockCards).find((c) => c.blockType === getWidgetType(widget))?.title
}

export const getWidget = (widget: BlockWidget): Widget | undefined => {
  if (widget.type === "widget") {
    return widget.data
  }

  return undefined
}

type ModelType<T extends WidgetType> = z.infer<(typeof WidgetModels)[T]["model"]>

export const getWidgetData = <T extends WidgetType>(
  widget: Widget | null | undefined,
  type: T
): ModelType<T> | null => {
  if (widget && widget.type === type) {
    return widget as ModelType<T>
  }

  return null
}

export type WidgetParamsBase = z.infer<typeof ZWidgetParamsBase>
export type BlockWidget = z.infer<typeof ZBlockWidget>
export type BlockCardProps = z.infer<typeof ZBlockCardProps>
export type WidgetParams = z.infer<typeof ZWidgetParams>
export type Widget = z.infer<typeof ZWidget>
export type WidgetType = keyof typeof WidgetModels

export type HeaderWidget = z.infer<typeof ZHeaderWidget>
export type MembersWidget = z.infer<typeof ZMembersWidget>
export type FeaturedProductsWidget = z.infer<typeof ZFeaturedProductsWidget>
export type ContentWidget = z.infer<typeof ZContentWidget>
export type EventWidget = z.infer<typeof ZEventWidget>
export type FeedWidget = z.infer<typeof ZFeedWidget>
export type RichTextWidget = z.infer<typeof ZRichTextWidget>
export type InstructorsWidget = z.infer<typeof ZInstructorsWidget>
export type AccordionWidget = z.infer<typeof ZAccordionWidget>
export type AccordionWidgetItem = z.infer<typeof ZAccordionWidgetItem>
export type ChatRoomWidget = z.infer<typeof ZChatRoomWidget>
export type CurriculumWidget = z.infer<typeof ZCurriculumWidget>
export type ColumnsWidget = z.infer<typeof ZColumnsWidget>
export type LinksWidget = z.infer<typeof ZLinksWidget>
export type LinkWidgetItem = z.infer<typeof ZLinkWidgetItem>
export type LinkWidgetItemNestedAttributes = z.infer<typeof ZLinkWidgetItemNestedAttributes>
export type PhotoWidget = z.infer<typeof ZPhotoWidget>

export const makeBlockWidgets = (widgets: Widget[], placement: WidgetPlacement): BlockWidget[] => {
  return widgets
    .filter((w) => w.placement === placement && !w.parent_id)
    .map((w): BlockWidgetWithChildren => {
      return {
        id: `${w.id}-${w.updated_at}`,
        type: "widget",
        data: w,
        children: widgetChildren(widgets, w),
      }
    })
    .sort((a, b) => a.data.position - b.data.position) as BlockWidget[]
}

const widgetChildren = (widgets: Widget[], parent: Widget): BlockWidget[] => {
  return widgets
    .filter((w) => w.parent_id === parent.id)
    .map((w): BlockWidget => {
      return {
        id: `${w.id}-${w.updated_at}`,
        type: "widget",
        data: w,
        children: [],
        parent: parent,
      }
    })
    .sort((a, b) => a.data.position - b.data.position)
}

export type BlockWidgetCardEvents = {
  deleteElement: [value: BlockWidget]
  editElement: [value: BlockWidget]
  duplicateElement: [value: BlockWidget]
  editedElement: [value: BlockWidget]
  draggableChanged: [
    data: {
      event: any
      parentId: string
    },
  ]
}

export const useWidgetBaseConfig = (config: WidgetBaseConfig | undefined | null) => {
  const hasPadding = computed(() => {
    return !config?.disable_padding
  })

  const hasTitle = computed(() => {
    return !config?.disable_title
  })

  return {
    hasPadding,
    hasTitle,
  }
}
