<template>
  <div class="flex flex-col" :class="[hasPadding && 'gap-4']">
    <gradient-image
      class="aspect-[21/9]"
      :class="[!hasTitle && 'rounded-t-lg']"
      :id="widgetId"
      :url="widget.cover_url" />

    <div class="flex flex-col gap-4" :class="[!hasPadding && 'p-4']">
      <div class="text-primary font-medium">{{ widget.subtitle }}</div>
      <h3 class="text-primary font-medium">{{ widget.title }}</h3>
      <div class="text-secondary whitespace-pre-line">{{ widget.body }}</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import GradientImage from "/js/components/GradientImage.vue"
import { type HeaderWidget, useWidgetBaseConfig } from "/js/components/BlockBuilder/Widget"

const props = defineProps<{
  widgetId: string
  widget: HeaderWidget
}>()

const { hasTitle, hasPadding } = useWidgetBaseConfig(props.widget)

</script>

<script lang="ts">
export default {
  name: "HeaderCard",
}
</script>
