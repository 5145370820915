<template>
  <div class="card w-full" :class="[cover && 'h-full']">
    <img
      :class="[cover ? 'h-full object-cover' : 'rounded-lg object-contain']"
      class="w-full"
      :src="widget.cover_url || undefined"
      alt="Photo" />
  </div>
</template>

<script setup lang="ts">
import type { PhotoWidget } from "/js/components/BlockBuilder/Widget"
import { computed } from "vue"

const props = defineProps<{
  widgetId: string
  widget: PhotoWidget
}>()

const cover = computed(() => {
  return props.widget.display_type === "cover"
})
</script>

<script lang="ts">
export default {
  name: "PhotoCard",
}
</script>
