import { useRoute, useRouter } from "vue-router"

export const useRouteUserModal = () => {
  const router = useRouter()
  const route = useRoute()

  const openUserModal = async (userId: string) => {
    await router.push({
      name: route.name,
      params: route.params,
      query: { userId }
    })
  }

  return {
    openUserModal,
  }
}