import { z } from "zod"

export const personalInfoResumeItemType = z.literal("PersonalInfoResumeItem")
export const summaryResumeItemType = z.literal("SummaryResumeItem")
export const educationResumeItemType = z.literal("EducationResumeItem")
export const experienceResumeItemType = z.literal("ExperienceResumeItem")
export const skillResumeItemType = z.literal("SkillResumeItem")
export const certificationResumeItemType = z.literal("CertificationResumeItem")
export const languageResumeItemType = z.literal("LanguageResumeItem")

const ZResumeItemTypes = z.enum([
  summaryResumeItemType.value,
  educationResumeItemType.value,
  experienceResumeItemType.value,
  skillResumeItemType.value,
  certificationResumeItemType.value,
  languageResumeItemType.value,
  personalInfoResumeItemType.value,
])

export type ResumeItemType = z.infer<typeof ZResumeItemTypes>

export const ZResumeItemBase = z.object({
  id: z.string().optional().nullable(),
  position: z.number()
})

export const ZPersonalInfoResumeItemData = z.object({
  first_name: z.string().nullable(),
  last_name: z.string().nullable(),
  avatar_url: z.string().nullable(),
  email: z.string().nullable(),
  phone: z.string().nullable(),
  address: z.string().nullable(),
  linkedin_url: z.string().nullable(),
})

export type PersonalInfoResumeItemParams = {
  id?: string | null
  position?: number
  first_name?: string | null
  last_name?: string | null
  avatar?: string | null
  email?: string | null
  phone?: string | null
  address?: string | null
  linkedin_url?: string | null
  type: "PersonalInfoResumeItem"
}

export const ZPersonalInfoResumeItem = z.object({
  type: personalInfoResumeItemType,
  ...ZPersonalInfoResumeItemData.shape,
  ...ZResumeItemBase.shape,
})

export const ZSummaryResumeItemData = z.object({
  text: z.string().nullable(),
})

export const ZSummaryResumeItem = z.object({
  type: summaryResumeItemType,
  ...ZSummaryResumeItemData.shape,
  ...ZResumeItemBase.shape,
})

export type SummaryResumeItem = z.infer<typeof ZSummaryResumeItem>
export type SummaryResumeItemParams = {
  type: "SummaryResumeItem"
  id?: string
  position?: number
  text: string
}

export const ZEducationResumeItemData = z.object({
  school: z.string(),
  degree: z.string().nullable(),
  field_of_study: z.string().nullable(),
  start_date: z.date().nullable(),
  end_date: z.date().nullable(),
  description: z.string().nullable(),
})

export const ZEducationResumeItem = z.object({
  type: educationResumeItemType,
  ...ZEducationResumeItemData.shape,
  ...ZResumeItemBase.shape,
})

export type EducationResumeItemParams = {
  id?: string
  position?: number
  school: string
  degree?: string | null
  field_of_study?: string | null
  start_date?: Date | null
  end_date?: Date | null
  description?: string | null
  type: "EducationResumeItem"
}

export const ZExperienceResumeItemData = z.object({
  company: z.string(),
  title: z.string(),
  start_date: z.date().nullable(),
  current_job: z.boolean(),
  location: z.string().nullable(),
  end_date: z.date().nullable(),
  description: z.string().nullable(),
})

export const ZExperienceResumeItem = z.object({
  type: experienceResumeItemType,
  ...ZExperienceResumeItemData.shape,
  ...ZResumeItemBase.shape,
})

export type ExperienceResumeItemParams = {
  id?: string
  position?: number
  company: string
  title: string
  location?: string | null
  start_date?: Date
  end_date?: Date | null
  current_job?: boolean
  description?: string | null
  type: "ExperienceResumeItem"
}

export const SkillProficiencies = ["beginner", "intermediate", "advanced", "expert"] as const
export type SkillProficiency = (typeof SkillProficiencies)[number]
export const ZSkillResumeItemData = z.object({
  skill_name: z.string(),
  skill_proficiency: z.enum(SkillProficiencies).nullable(),
})

export type SkillResumeItemParams = {
  id?: string
  position?: number
  skill_name: string
  skill_proficiency?: SkillProficiency | null
  type: "SkillResumeItem"
}

export const SkillProficiencyLabels: Record<SkillProficiency, string> = {
  beginner: "Beginner",
  intermediate: "Intermediate",
  advanced: "Advanced",
  expert: "Expert",
}

export const ZSkillResumeItem = z.object({
  type: skillResumeItemType,
  ...ZSkillResumeItemData.shape,
  ...ZResumeItemBase.shape,
})

export const ZCertificationResumeItemData = z.object({
  certification_name: z.string(),
  issuing_org: z.string().nullable(),
  issue_date: z.date().nullable(),
  expiration_date: z.date().nullable(),
  credential_id: z.string().nullable(),
  credential_url: z.string().nullable(),
})

export const ZCertificationResumeItem = z.object({
  type: certificationResumeItemType,
  ...ZCertificationResumeItemData.shape,
  ...ZResumeItemBase.shape,
})

export type CertificationItemParams = {
  id?: string
  position?: number
  certification_name: string
  issuing_org: string
  issue_date?: Date | null
  expiration_date?: Date | null
  credential_id?: string | null
  credential_url?: string | null
  type: "CertificationResumeItem"
}

export const ZLanguageProficiencies = [
  "elementary",
  "limited",
  "professional",
  "full_professional",
  "native",
] as const

export type LanguageProficiency = (typeof ZLanguageProficiencies)[number]

export const ZLanguageResumeItemData = z.object({
  language_name: z.string(),
  language_proficiency: z.enum(ZLanguageProficiencies).nullable().optional(),
})

export const ZLanguageResumeItem = z.object({
  type: languageResumeItemType,
  ...ZLanguageResumeItemData.shape,
  ...ZResumeItemBase.shape,
})

export type LanguageResumeItemParams = {
  id?: string
  position?: number
  language_name: string
  language_proficiency?: LanguageProficiency | null
  type: "LanguageResumeItem"
}

export const LanguageProficiencyLabels: Record<LanguageProficiency, string> = {
  elementary: "Elementary",
  limited: "Limited",
  professional: "Professional",
  full_professional: "Full Professional",
  native: "Native",
}

export const ZResumeItem = z.union([
  ZPersonalInfoResumeItem,
  ZEducationResumeItem,
  ZExperienceResumeItem,
  ZSkillResumeItem,
  ZCertificationResumeItem,
  ZLanguageResumeItem,
  ZSummaryResumeItem,
])

export type ResumeItem = z.infer<typeof ZResumeItem>
export type PersonalInfoResumeItem = z.infer<typeof ZPersonalInfoResumeItem>
export type EducationResumeItem = z.infer<typeof ZEducationResumeItem>
export type ExperienceResumeItem = z.infer<typeof ZExperienceResumeItem>
export type SkillResumeItem = z.infer<typeof ZSkillResumeItem>
export type CertificationResumeItem = z.infer<typeof ZCertificationResumeItem>
export type LanguageResumeItem = z.infer<typeof ZLanguageResumeItem>

export type ResumeItemParams =
  | PersonalInfoResumeItemParams
  | EducationResumeItemParams
  | ExperienceResumeItemParams
  | SkillResumeItemParams
  | CertificationItemParams
  | LanguageResumeItemParams
  | SummaryResumeItemParams

export type ResumeItemsByCategory = {
  educationItems: EducationResumeItem[]
  experienceItems: ExperienceResumeItem[]
  skillItems: SkillResumeItem[]
  certificationItems: CertificationResumeItem[]
  languageItems: LanguageResumeItem[]
  summaryItems: SummaryResumeItem[]
  personalInfoItems: PersonalInfoResumeItem[]
}

export const groupResumeItemsByType = (items: ResumeItem[]): ResumeItemsByCategory => {
  const groupedItems: ResumeItemsByCategory = {
    educationItems: [],
    experienceItems: [],
    skillItems: [],
    certificationItems: [],
    languageItems: [],
    summaryItems: [],
    personalInfoItems: [],
  }
  items.forEach((item) => {
    switch (item.type) {
      case "EducationResumeItem":
        groupedItems.educationItems.push(item)
        break
      case "ExperienceResumeItem":
        groupedItems.experienceItems.push(item)
        break
      case "SkillResumeItem":
        groupedItems.skillItems.push(item)
        break
      case "CertificationResumeItem":
        groupedItems.certificationItems.push(item)
        break
      case "LanguageResumeItem":
        groupedItems.languageItems.push(item)
        break
      case "SummaryResumeItem":
        groupedItems.summaryItems.push(item)
        break
      case "PersonalInfoResumeItem":
        groupedItems.personalInfoItems.push(item)
        break
    }
  })
  return groupedItems
}
