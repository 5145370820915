<template>
  <loading-view :loading="isLoading">
    <members-card :users="users" v-if="users && users.length > 0" />
    <empty-view title="No members found" v-else>
      <members-empty-icon class="h-40 w-40" />
    </empty-view>
    <error-message :error="error" v-if="error" />
    <router-link
      class="w-full btn btn-outline"
      v-if="users && users.length > 0"
      :to="{ name: 'community_members.index' }">
      View All Members
    </router-link>
  </loading-view>
</template>

<script setup lang="ts">
import { useQuery } from "@tanstack/vue-query"
import MembersCard from "/js/components/BlockBuilder/Cards/MembersCard.vue"
import ErrorMessage from "/js/components/ErrorMessage.vue"
import type { MembersWidget } from "/js/components/BlockBuilder/Widget"
import { WidgetsApi } from "/js/services/WidgetsApi"
import { computed } from "vue"
import EmptyView from "/js/components/utilities/UI/EmptyView.vue"
import MembersEmptyIcon from "/js/components/icons/MembersEmptyIcon.vue"
import LoadingView from "/js/components/LoadingView.vue"

const props = defineProps<{
  widgetId: string
  widget: MembersWidget
}>()

const {
  data: users,
  isLoading,
  error,
} = useQuery({
  queryKey: computed(() => ["widget_member_list", props.widgetId, props.widget]),
  queryFn: async () => await WidgetsApi.getMembers(props.widgetId),
})

const title = computed(() => {
  switch (props.widget.member_type) {
    case "admin_member":
      return "Community Admins"
    case "user_member":
      return "Community Members"
    case "all_member":
      return "Members"
    default:
      return "Members"
  }
})
</script>

<script lang="ts">
export default {
  name: "CommunityMembersCardLoader",
}
</script>
