<template>
  <header-card v-if="widget.type === 'HeaderWidget'" :widget-id="widget.id" :widget />

  <community-events-card-loader
    v-else-if="widget.type === 'EventWidget'"
    :widget-id="widget.id"
    :widget />

  <community-featured-products-loader
    v-if="widget.type === 'FeaturedProductsWidget'"
    :widget-id="widget.id"
    :widget />

  <community-members-card-loader
    v-else-if="widget.type === 'MembersWidget'"
    :widget-id="widget.id"
    :widget />

  <product-instructors-card-loader
    v-else-if="widget.type === 'InstructorsWidget'"
    :widget-id="widget.id"
    :widget />

  <community-feed-card-loader
    v-else-if="widget.type === 'FeedWidget'"
    :widget-id="widget.id"
    :widget />

  <chat-room-card-loader v-else-if="widget.type === 'ChatRoomWidget'" :widget/>

  <curriculum-card-loader
    v-else-if="widget.type === 'CurriculumWidget'"
    :widget-id="widget.id"
    :widget />

  <rich-text-card v-else-if="widget.type === 'RichTextWidget'" :widget/>

  <content-card v-else-if="widget.type === 'ContentWidget'" :widget :widgetId="widget.id"/>

  <accordion-card v-else-if="widget.type === 'AccordionWidget'" :widget/>

  <columns-card
    v-else-if="widget.type === 'ColumnsWidget'"
    :widget
    :edit
    :children
    :updateWidget
    @editedElement="emit('editedElement', $event)"
    @draggableChanged="emit('draggableChanged', $event)"
    @deleteElement="emit('deleteElement', $event)"
    @duplicateElement="emit('duplicateElement', $event)"
    @editElement="emit('editElement', $event)" />

  <photo-card v-else-if="widget.type === 'PhotoWidget'" :widgetId="widget.id" :widget />
  <links-card v-else-if="widget.type === 'LinksWidget'" :widget />

</template>

<script setup lang="ts">
import CommunityEventsCardLoader from "/js/components/BlockBuilder/Cards/CommunityEventsCardLoader.vue"
import RichTextCard from "/js/components/BlockBuilder/Cards/RichTextCard.vue"
import AccordionCard from "/js/components/BlockBuilder/Cards/AccordionCard.vue"
import ProductInstructorsCardLoader from "/js/components/BlockBuilder/Cards/ProductInstructorsCardLoader.vue"
import CommunityMembersCardLoader from "/js/components/BlockBuilder/Cards/CommunityMembersCardLoader.vue"
import CommunityFeedCardLoader from "/js/components/BlockBuilder/Cards/CommunityFeedCardLoader.vue"
import HeaderCard from "/js/components/BlockBuilder/Cards/HeaderCard.vue"
import CommunityFeaturedProductsLoader from "/js/components/BlockBuilder/Cards/CommunityFeaturedProductsLoader.vue"
import ContentCard from "/js/components/BlockBuilder/Cards/ContentCard.vue"
import type { BlockWidget, BlockWidgetCardEvents, Widget, WidgetParams } from "/js/components/BlockBuilder/Widget"
import ChatRoomCardLoader from "/js/components/BlockBuilder/Cards/ChatRoomCardLoader.vue"
import CurriculumCardLoader from "/js/components/BlockBuilder/Cards/CurriculumCardLoader.vue"
import ColumnsCard from "/js/components/BlockBuilder/Cards/ColumnsCard.vue"
import PhotoCard from "/js/components/BlockBuilder/Cards/PhotoCard.vue"
import LinksCard from "/js/components/BlockBuilder/Cards/LinksCard.vue"

defineProps<{
  widget: Widget
  children?: BlockWidget[]
  edit?: boolean
  updateWidget?: (widgetId: string, params: Partial<WidgetParams>) => Promise<Widget>
}>()

const emit = defineEmits<BlockWidgetCardEvents>()

</script>

<script lang="ts">
export default {
  name: "BlockWidgetCardElement",
}
</script>
