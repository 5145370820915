import type { Tag } from "/js/models/Tag"
import { ZTag } from "/js/models/Tag"
import { customTimeAgo } from "/js/composables/customTimeAgo"
import type {
  CommunityRole,
  ProductRole,
  UserGroupType,
} from "/js/services/permissionPolicyService"
import { ProductRoles } from "/js/services/permissionPolicyService"
import { z } from "zod"
import { notNullish } from "@vueuse/core"
import { ZEventAttendance } from "/js/services/EventAttendance"

export const ZUser = z.object({
  id: z.string(),
  first_name: z.string().nullable(),
  last_name: z.string().nullable(),
  avatar_url: z.string().nullable(),
  cover_url: z.string().nullable(),
  description: z.string().nullable(),
  tags: z.array(ZTag).optional(),
  online: z.boolean().nullable(),
  last_online_at: z.date().nullable(),
  product_role: z.enum(ProductRoles).optional(),
  verified: z.boolean().optional().nullable(),
  time_zone: z.string().optional().nullable(),
})

export type UserCommunityRole = {
  community_role: CommunityRole
}

export type User = {
  id: string
  first_name?: string
  last_name?: string
  avatar_url?: string
  cover_url?: string
  description?: string
  tags?: Tag[]
  online?: boolean
  last_online_at?: Date
  product_role?: ProductRole // Only used when listing users for a product
  verified?: boolean
  time_zone?: string
}

export type GroupCount = {
  type: UserGroupType
  count: number
}

export type AdminMember = User & {
  user_group_counts: GroupCount[]
  email: string
  joined_at?: Date
}

export const ZUserWithName = z.object({
  id: z.string(),
  first_name: z.string().nullable(),
  last_name: z.string().nullable(),
})

export type UserWithName = z.infer<typeof ZUserWithName>

export const ZUserWithAttendance = ZUser.extend({
  attendance: ZEventAttendance.nullable(),
})

export const ZUserProfile = ZUser.extend({
  email: z.string(),
  unconfirmed_email: z.string().nullable(),
})

export type UserProfile = z.infer<typeof ZUserProfile>
export type UserWithAttendance = z.infer<typeof ZUserWithAttendance>

type UserName = {
  first_name?: string | null
  last_name?: string | null
}

export const fullName = <T extends UserName>(user: T | undefined) => {
  if (!user) return ""
  else if (!user.first_name && !user.last_name) return "N/A"
  return [user.first_name, user.last_name].filter(notNullish).join(" ")
}

export const initials = <T extends UserName>(user: T | undefined) => {
  if (!user) return ""
  return nameInitials(user.first_name, user.last_name)
}

export const nameInitials = (
  firstName: string | null | undefined,
  lastName: string | null | undefined
) => {
  return [firstName, lastName]
    .filter(notNullish)
    .map((s) => s[0])
    .join("")
    .toUpperCase()
}

export const tagsDescription = (user: User) => {
  if (!user.tags) {
    return null
  }
  return makeString(user.tags.map((t) => t.name))
}

export const lastOnline = (user: User) => {
  if (user.online) {
    return "Online"
  } else if (user.last_online_at) {
    return `Last seen ${customTimeAgo(user.last_online_at, true)}`
  } else {
    return "Offline"
  }
}

function makeString(arr: string[]) {
  if (arr.length === 0) return null
  if (arr.length === 1) return arr[0]
  const firsts = arr.slice(0, arr.length - 1)
  const last = arr[arr.length - 1]
  return firsts.join(", ") + " and " + last
}
