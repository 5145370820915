<template>
  <router-link :to v-slot="{ href, navigate, isActive }">
    <span class="flex flex-col items-center">
      <span class="rounded-lg p-2" :class="{ 'bg-gray-300': isActive }">
        <slot name="icon" v-bind="{ isActive }" />
      </span>
      <span class="text-xxs">{{ title }}</span>
    </span>
  </router-link>
</template>

<script setup lang="ts">
import type { RouteLocationRaw } from "vue-router"

defineProps<{
  to: RouteLocationRaw
  title: string
}>()

</script>

<script lang="ts">
export default {
  name: "SidebarRouterLink",
}
</script>