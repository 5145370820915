<template>
  <div class="relative">
    <div
      class="paged-cards-parent flex w-full items-stretch gap-4 overflow-x-scroll scrollbar:h-0 scrollbar:w-0"
      ref="scroller">
      <slot></slot>
    </div>

    <div class="pointer-events-none absolute inset-0 flex items-center gap-2">
      <div class="flex w-full items-center justify-between">
        <button
          class="z-10 bg-secondary text-secondary hover:bg-tertiary disabled:text-secondary disabled:hover:bg-secondary pointer-events-auto -ml-2 rounded-lg px-3 py-3 shadow-lg disabled:opacity-20"
          @click="scrollLeft"
          :disabled="arrivedState.left">
          <chevron-left-icon />
        </button>
        <button
          class="z-10 bg-secondary text-secondary hover:bg-tertiary disabled:text-secondary disabled:hover:bg-secondary pointer-events-auto -mr-2 rounded-lg px-3 py-3 shadow-lg disabled:opacity-20"
          @click="scrollRight"
          :disabled="arrivedState.right && x > 0">
          <chevron-right-icon />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import ChevronLeftIcon from "/js/components/icons/ChevronLeftIcon.vue"
import ChevronRightIcon from "/js/components/icons/ChevronRightIcon.vue"
import { ref, computed } from "vue"
import { useScroll } from "@vueuse/core"

const scroller = ref<HTMLElement | null>(null)
const { arrivedState, x } = useScroll(scroller)
const cardWidth = computed(() => {
  return scroller.value?.children[0]?.clientWidth ?? 0
})

// the 20 accounts for gaps/borders between cards
const scrollRight = () => {
  scroller.value?.scrollBy({ left: cardWidth.value + 20, behavior: "smooth" })
}

const scrollLeft = () => {
  scroller.value?.scrollBy({ left: -cardWidth.value - 20, behavior: "smooth" })
}
</script>

<script lang="ts">
export default {
  name: "PagedCards",
}
</script>
<style>
.full-width-paged-cards-parent > * {
  @apply w-full max-w-full;
}

.paged-cards-parent > * {
  @apply w-full max-w-sm shrink-0;
}
</style>
