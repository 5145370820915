<template>
  <block-card
    v-if="element.type === 'block'"
    :title="element.data.title"
    :description="element.data.description"
    :bg="element.data.bg"
    :block-type="element.data.blockType" />
  <template v-else-if="element.type === 'widget'">
    <div class="relative h-full w-full">
      <WidgetCard :widget="element.data">
        <block-widget-card-element
          :widget="element.data"
          :children="element.children"
          :edit
          :updateWidget
          @deleteElement="emit('deleteElement', $event)"
          @editElement="emit('editElement', $event)"
          @duplicateElement="emit('duplicateElement', $event)"
          @editedElement="emit('editedElement', $event)"
          @draggableChanged="emit('draggableChanged', $event)" />
      </WidgetCard>

      <div
        class="absolute top-2 z-10"
        :class="[hasDropdownRightOffset ? '-right-10' : 'right-2']"
        v-if="edit">
        <dropdown-menu :portal="true" menuItemsWrapperClass="z-10">
          <div class="text-secondary px-4 py-3 text-xs">
            {{ getWidgetTypeTitle(element) }} Widget
          </div>
          <template #button>
            <icon-button>
              <ellipsis-icon class="h-4 w-4 text-gray-600 hover:text-gray-500" aria-hidden="true" />
            </icon-button>
          </template>

          <hr />

          <dropdown-menu-item
            @click="handleToggle('disable_padding')"
            class="flex items-center justify-between gap-2">
            Card Padding
            <CheckedIcon class="h-4 w-4" v-if="!element.data.disable_padding" />
            <UncheckedIcon class="h-4 w-4" v-else />
          </dropdown-menu-item>

          <dropdown-menu-item
            @click="handleToggle('disable_card')"
            class="flex items-center justify-between gap-2">
            Card Background
            <CheckedIcon class="h-4 w-4" v-if="!element.data.disable_card" />
            <UncheckedIcon class="h-4 w-4" v-else />
          </dropdown-menu-item>

          <dropdown-menu-item
            @click="handleToggle('disable_title')"
            class="flex items-center justify-between gap-2">
            Card Title
            <CheckedIcon class="h-4 w-4" v-if="!element.data.disable_title" />
            <UncheckedIcon class="h-4 w-4" v-else />
          </dropdown-menu-item>

          <dropdown-menu-item
            @click="handleToggle('enable_title_padding')"
            class="flex items-center justify-between gap-2">
            Card Title Padding
            <CheckedIcon class="h-4 w-4" v-if="element.data.enable_title_padding" />
            <UncheckedIcon class="h-4 w-4" v-else />
          </dropdown-menu-item>

          <dropdown-menu-item v-if="!element.data.parent_id" class="flex flex-col gap-2">
            <div class="flex justify-between">
              <span>Max Width</span>

              <button
                class="btn btn-xs primary-link"
                type="button"
                @click="handleMaxWidth(null, element.data.id)"
                v-if="element.data.card_horizontal_margin">
                Clear
              </button>
            </div>
            <div class="grid grid-cols-6 gap-2">
              <button
                type="button"
                v-for="size in WidgetCardHorizontalMarginSizes"
                class="btn btn-xs"
                :class="[
                  element.data.card_horizontal_margin === size ? 'btn-primary' : 'btn-outline',
                ]"
                @click="handleMaxWidth(size, element.data.id)"
                :key="size">
                {{ size }}
              </button>
            </div>
          </dropdown-menu-item>

          <template v-if="element.data.type === 'ColumnsWidget'">
            <dropdown-menu-item
              class="flex items-center justify-between gap-2"
              @click="handleToggle('no_gap')">
              No Spacing Between Widgets
              <CheckedIcon class="h-4 w-4" v-if="element.data.no_gap" />
              <UncheckedIcon class="h-4 w-4" v-else />
            </dropdown-menu-item>

            <dropdown-menu-item
              class="flex items-center justify-between gap-2"
              @click="handleToggle('carousel')">
              Display as Carousel
              <CheckedIcon class="h-4 w-4" v-if="element.data.carousel" />
              <UncheckedIcon class="h-4 w-4" v-else />
            </dropdown-menu-item>
          </template>

          <template v-if="element.parent">
            <ColumnCardChildDropdownOptions
              :updateWidget
              @edited="emit('editedElement', element)"
              :parent="element.parent"
              :widget="element.data"
              :children="element.children" />
          </template>

          <template v-if="element.data.type === 'PhotoWidget' && element.parent">
            <PhotoCardDropdownOptions
              :widget="element.data"
              :updateWidget
              @edited="emit('editedElement', element)" />
          </template>

          <hr />

          <dropdown-menu-item @click="emit('editElement', element)">Edit</dropdown-menu-item>

          <dropdown-menu-item
            v-if="element.data.type !== 'ColumnsWidget'"
            @click="emit('duplicateElement', element)">
            Duplicate
          </dropdown-menu-item>

          <dropdown-menu-item variant="danger" @click="deleteElement = element">
            Delete
          </dropdown-menu-item>
        </dropdown-menu>
      </div>
    </div>

    <confirmation-item-modal
      title="Delete this widget?"
      v-model="deleteElement"
      @confirm="handleDelete"
      #default="{ data }">
      Delete this {{ getWidgetTypeTitle(data) }} widget?
    </confirmation-item-modal>
  </template>
</template>

<script setup lang="ts">
import {
  type BlockWidget,
  type BlockWidgetCardEvents,
  type Widget,
  type WidgetCardHorizontalMarginSize,
  WidgetCardHorizontalMarginSizes,
  type WidgetParams,
} from "/js/components/BlockBuilder/Widget"
import BlockCard from "/js/components/BlockBuilder/Cards/BlockCard.vue"
import DropdownMenu from "/js/components/DropdownMenu.vue"
import DropdownMenuItem from "/js/components/DropdownMenuItem.vue"
import ConfirmationItemModal from "/js/components/Modals/ConfirmationItemModal.vue"
import { computed, ref } from "vue"
import { getWidgetTypeTitle } from "/js/components/BlockBuilder/Widget"
import BlockWidgetCardElement from "/js/components/BlockBuilder/Cards/BlockWidgetCardElement.vue"
import IconButton from "/js/components/IconButton.vue"
import EllipsisIcon from "/js/components/icons/EllipsisIcon.vue"
import WidgetCard from "/js/components/BlockBuilder/Cards/WidgetCard.vue"
import CheckedIcon from "/js/components/icons/CheckedIcon.vue"
import UncheckedIcon from "/js/components/icons/UncheckedIcon.vue"
import ColumnCardChildDropdownOptions from "/js/components/BlockBuilder/Cards/ColumnCardChildDropdownOptions.vue"
import PhotoCardDropdownOptions from "/js/components/BlockBuilder/Cards/PhotoCardDropdownOptions.vue"

const {
  edit = false,
  element,
  updateWidget,
} = defineProps<{
  element: BlockWidget
  edit?: boolean
  updateWidget?: (widgetId: string, params: Partial<WidgetParams>) => Promise<Widget>
}>()

const emit = defineEmits<BlockWidgetCardEvents>()

const deleteElement = ref<BlockWidget | null>(null)

const handleDelete = () => {
  if (!deleteElement.value) {
    return
  }
  emit("deleteElement", element)
  deleteElement.value = null
}

const handleToggle = async (key: string) => {
  const params = {
    [key]: !element.data?.[key],
  }

  if (edit && element.data.id && updateWidget) {
    await updateWidget(element.data.id, params)
    emit("editedElement", element)
  }
}

const hasDropdownRightOffset = computed(() => {
  return !element.data?.parent_id
})

const handleMaxWidth = async (size: WidgetCardHorizontalMarginSize | null, widgetId: string) => {
  if (!updateWidget) return
  await updateWidget(widgetId, { card_horizontal_margin: size })
  emit("editedElement", element)
}
</script>

<script lang="ts">
export default {
  name: "BlockWidgetCard",
}
</script>
