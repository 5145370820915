import { computed } from "vue"
import { useSimpleQuery } from "/js/composables/useSimpleQuery"
import { PlatformPlansApi } from "/js/services/PlatformPlansApi"
import { PlansApi } from "/js/services/PlansApi"

const memberSubscriptionEnabled = window.APP_CONFIG.MEMBER_SUBSCRIPTION

const { data, isLoading, isSuccess, fetchData } = useSimpleQuery(async () => {
  if (!memberSubscriptionEnabled) return null
  return await PlansApi.getSubscription()
})

const {
  data: subscriptionTokens,
  isLoading: isLoadingTokens,
  isSuccess: isSuccessTokens,
  fetchData: fetchTokens,
} = useSimpleQuery(async () => {
  if (!memberSubscriptionEnabled) return null
  return await PlansApi.getSubscriptionTokens()
})

export type AccessStatus = "loading" | "enabled" | "disabled"

export const useMemberSubscription = () => {
  const memberAccessStatus = computed((): AccessStatus => {
    if (!memberSubscriptionEnabled) return "enabled"
    if (isLoading.value) return "loading"
    if (!isSuccess.value) return "disabled"
    return !!data.value ? "enabled" : "disabled"
  })

  const tokenAccessStatus = computed((): AccessStatus => {
    if (!memberSubscriptionEnabled) return "enabled"
    if (isLoadingTokens.value) return "loading"
    if (!isSuccessTokens.value) return "disabled"
    return !!subscriptionTokens.value ? "enabled" : "disabled"
  })

  const productSubscriptionRequired = (productId: string) => {
    return !subscriptionTokens.value?.find((pid) => pid === productId)
  }

  const hasTokens = computed(() => {
    return !!subscriptionTokens.value && subscriptionTokens.value.length > 0
  })

  return {
    memberAccessStatus,
    tokenAccessStatus,
    fetchData,
    fetchTokens,
    productSubscriptionRequired,
    hasTokens
  }
}
