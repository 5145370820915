<template>
  <div class="group flex h-full w-full items-center gap-3 text-start" v-if="community">
    <slot name="logo" v-bind="{ community }">
      <community-logo :community class="!w-10" />
    </slot>

    <div class="flex flex-1 flex-col gap-0.5 truncate items-start">
      <div class="flex items-center gap-2">
        <span class="truncate text-sm text-gray-700 dark:text-white">
          {{ community.name }}
        </span>
        <unread-dot v-if="hasUnreadNotifications"/>
      </div>

      <span class="truncate text-xs text-ll-green-200">
        {{ communityHostname }}
      </span>
    </div>

    <arrow-right-circle-icon
      class="ml-4 h-6 w-6 shrink-0 text-ll-green-200 transition-all duration-200 group-hover:translate-x-0.5 group-hover:text-gray-500 dark:group-hover:text-white" />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"
import { ArrowRightCircleIcon } from "@heroicons/vue/24/outline"
import CommunityLogo from "/js/components/Layout/CommunityLogo.vue"
import type { Community } from "/js/models/Community"
import UnreadDot from "/js/components/icons/UnreadDot.vue";

const props = defineProps<{
  community: Community,
  hasUnreadNotifications: boolean,
}>()

const communityHostname = computed(() => {
  if (!props.community) return null
  const url = new URL(props.community.community_url)
  return url.hostname
})
</script>

<script lang="ts">
export default {
  name: "CommunityNavButton",
}
</script>
