<template>
  <div v-if="error" class="flex h-screen w-screen flex-col items-center justify-center gap-4">
    <error-message :error="error" />
    <button class="btn btn-primary" @click="reloadData">Reload</button>
  </div>
  <loading-view v-else :loading="isLoading">
    <learning-hub-overview :productId class="min-h-screen bg-gray-50" :show-product-name="true">
      <template #gradient-overlay>
        <div class="padding main-content-inset flex flex-col gap-4">
          <div class="flex justify-end" v-if="user">
            <dropdown-menu>
              <template #button>
                <round-avatar
                  :id="user.id"
                  :verified="user.verified"
                  :avatar-url="user.avatar_url"
                  :text="initials(user)" />
              </template>
              <template #default>
                <dropdown-menu-item @click="handleLogout">Log Out</dropdown-menu-item>
              </template>
            </dropdown-menu>
          </div>
        </div>
      </template>
      <template #right-actionbar>
        <div class="flex gap-4">
          <!-- Restricted -->
          <div v-if="!isMainActionable" class="flex items-center gap-4">
            <lock-closed-icon class="h-10 w-10 text-teal-500"></lock-closed-icon>
            <div>
              Access to this Learning Hub is restricted
              <div class="text-secondary text-xs">
                Only invited Members may join this Learning Hub.
              </div>
            </div>
          </div>

          <!-- Join button / actions -->
          <button
            class="btn btn-primary"
            @click="handleMainAction"
            :disabled="mainActionLoading"
            v-if="isMainActionable">
            {{ mainActionText }}
          </button>
        </div>
      </template>
    </learning-hub-overview>

    <modal v-model="showRegistrationForm">
      <div class="px-8 pb-8" v-if="registrationType">
        <registration-container v-model="registrationType" />
      </div>
    </modal>

    <modal v-model="showModalPlanSelector" size-type="fullScreen">
      <loading-view :loading="subscriptionLoading">
        <plan-selector
          :subscription="subscription"
          :product-id="productId"
          @subscriptionChanged="handleSubscriptionChanged" />
      </loading-view>
    </modal>
  </loading-view>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue"
import LoadingView from "/js/components/LoadingView.vue"
import ErrorMessage from "/js/components/ErrorMessage.vue"
import { LockClosedIcon } from "@heroicons/vue/24/outline"
import { initials } from "/js/models/User"
import RoundAvatar from "/js/components/RoundAvatar.vue"
import DropdownMenu from "/js/components/DropdownMenu.vue"
import DropdownMenuItem from "/js/components/DropdownMenuItem.vue"
import { UsersApi } from "/js/services/UsersApi"
import { useCurrentUserService } from "/js/services/useCurrentUserService"
import { useCommunityService } from "/js/services/useCommunityService"
import { useQuery } from "@tanstack/vue-query"
import { productQueryKey } from "/js/services/useProductService"
import { useRoute, useRouter } from "vue-router"
import { ProductsApi } from "/js/services/ProductsApi"
import Modal from "/js/components/Modals/Modal.vue"
import RegistrationContainer from "/js/components/Registration/RegistrationContainer.vue"
import type { RegistrationType } from "/js/components/Registration/RegistrationTypes"
import { namedRoutes } from "/js/router/LearningHubRoutes"
import { toastError } from "/js/composables/toast"
import PlanSelector from "/js/components/Subscriptions/PlanSelector.vue"
import { PlansApi, subscriptionQueryKey } from "/js/services/PlansApi"
import { useGate } from "/js/composables/useGate"
import LearningHubOverview from "/js/components/LearningHubs/Overview/LearningHubOverview.vue"
import { InvitationsApi } from "/js/services/InvitationsApi"

type MainActionType =
  | "sign_in"
  | "join"
  | "members_only"
  | "invite_only"
  | "view_product"
  | "subscribe"

const props = defineProps<{
  productId: string
}>()

const currentUserService = useCurrentUserService()
const communityService = useCommunityService()
const route = useRoute()
const productId = computed(() => route.params.product_id as string)
const mainActionLoading = ref(false)

const showRegistrationForm = ref(false)

const registrationType = ref<RegistrationType | null>(null)
const isSignedIn = computed(() => !!window.APP_CONFIG.USER_ID)
const router = useRouter()
const showModalPlanSelector = ref(false)
const { productGateStatus } = useGate()
const { communityGateStatus } = useGate()

const memberSubscriptionsEnabled = window.APP_CONFIG.MEMBER_SUBSCRIPTION

watch(registrationType, (value) => {
  if (value) {
    showRegistrationForm.value = true
  }
})

watch(showRegistrationForm, (value) => {
  if (!value) {
    // wait for transition before removing the registration type,
    // otherwise the modal content will disappear before the modal closes
    setTimeout(() => {
      registrationType.value = null
    }, 200)
  }
})

const {
  data: invitation,
  refetch: refetchInvitation,
  isLoading: invitationLoading,
} = useQuery({
  queryKey: computed(() => ["invitation", "Product", productId.value]),
  queryFn: async () => await InvitationsApi.getInvitationByInvitable("Product", productId.value),
})

const {
  data: user,
  refetch: refetchUser,
  isLoading: userLoading,
} = currentUserService.load(isSignedIn.value)

const {
  data: subscription,
  refetch: refetchSubscription,
  isLoading: subscriptionLoading,
} = useQuery({
  queryKey: subscriptionQueryKey,
  queryFn: async () => await PlansApi.getSubscription(),
  enabled: computed(() => memberSubscriptionsEnabled && isSignedIn.value),
})

const {
  data: community,
  error: communityError,
  refetch: refetchCommunity,
  isLoading: communityLoading,
} = communityService.loadCurrentCommunity()

const {
  data: product,
  error: productError,
  isLoading: productLoading,
} = useQuery({
  queryKey: computed(() => productQueryKey(productId.value)),
  queryFn: async () => {
    if (productId.value) {
      return await ProductsApi.getProduct(productId.value)
    } else {
      return null
    }
  },
  enabled: computed(() => !!productId.value),
})

const isLoading = computed(
  () =>
    userLoading.value ||
    communityLoading.value ||
    productLoading.value ||
    subscriptionLoading.value ||
    invitationLoading.value
)

const error = computed(() => communityError.value || productError.value)

const reloadData = () => {
  refetchUser()
  refetchCommunity()
  refetchSubscription()
  refetchInvitation()
}

const handleMainAction = async () => {
  if (mainActionLoading.value) {
    console.log("main action loading")
    return
  }

  if (!product.value) {
    console.log("no product")
    return
  }

  mainActionLoading.value = true

  switch (mainAction.value) {
    case "sign_in":
      registrationType.value = "signIn"
      break
    case "join":
      console.log("join")
      try {
        await ProductsApi.joinProduct(product.value.id)
        window.location.reload()
      } catch (e) {
        toastError(e)
      }
      break
    case "subscribe":
      showModalPlanSelector.value = true
      break
    case "members_only":
      await router.replace({
        name: 'community_registration_app'
      })
      break;
    case "view_product":
      await router.replace({
        name: namedRoutes.learning_hubs_show,
        params: { product_id: product.value.id },
      })
  }

  mainActionLoading.value = false
}

const handleLogout = async () => {
  await UsersApi.logout()
  window.location.reload()
}

const currentStatus = computed(() => {
  return productGateStatus(productId.value, "product_read")
})

const mainAction = computed((): MainActionType => {
  if (!isSignedIn.value || !product.value) {
    // todo: error state if product missing?
    return "sign_in"
  }

  const status = currentStatus.value

  if (status === "authorized") {
    return "view_product"
  } else if (status === "requires_permission") {
    if (
      product.value.access === "public_product" || invitation.value ||
      (product.value.access === "members" &&
        communityGateStatus("community_read") === "authorized")
    ) {
      return "join"
    } else {
      return "members_only"
    }
  } else if (status === "requires_subscription") {
    return "subscribe"
  }

  return "sign_in"
})

const isMainActionable = computed(() => {
  const actionableTypes: MainActionType[] = ["join", "sign_in", "view_product", "subscribe", "members_only"]
  return actionableTypes.includes(mainAction.value)
})

const mainActionText = computed(() => {
  switch (mainAction.value) {
    case "sign_in":
      return "Sign in to join"
    case "join":
      return "Join"
    case "members_only":
      return "Join Community"
    case "view_product":
      return "View Learning Hub"
    case "subscribe":
      return "Subscribe"
    default:
      return "Restricted"
  }
})

const handleSubscriptionChanged = () => {
  window.location.reload()
}
</script>

<script lang="ts">
export default {
  name: "ProductRegistrationApp",
}
</script>
