import axios from "axios"
import type { AxiosResponseHeaders, RawAxiosResponseHeaders } from "axios"
import { z } from "zod"

export const useAxios = axios.create()

useAxios.defaults.baseURL = window.APP_CONFIG.BASE_URL
useAxios.defaults.withCredentials = true

// Alter defaults after instance has been created
useAxios.defaults.headers.common["Content-Type"] = "application/json"
useAxios.defaults.headers.common["Accept"] = "application/json"

// add a header to every request with the community domain
useAxios.interceptors.request.use(function (config) {
  const communityId = window.APP_CONFIG.COMMUNITY_ID
  if (communityId) {
    config.headers["X-Community-Id"] = communityId
  }
  return config
})

export interface Pagy {
  currentPage: number
  pageItems: number
  totalPages: number
  totalCount: number
}

export interface PaginationData<T> {
  data: T
  pagy: Pagy
}

const pagy = (headers: RawAxiosResponseHeaders | AxiosResponseHeaders): Pagy => {
  return {
    currentPage: parseInt(headers["current-page"], 10),
    pageItems: parseInt(headers["page-items"], 10),
    totalCount: parseInt(headers["total-count"], 10),
    totalPages: parseInt(headers["total-pages"], 10),
  }
}

export async function getPaginated<T>(url: string): Promise<PaginationData<T>> {
  try {
    const response = await useAxios.get<T>(url)
    return {
      data: response.data,
      pagy: pagy(response.headers),
    }
  } catch (error) {
    throw error
  }
}

export async function getZodPaginated<T extends z.ZodType<any, any, any>>(
  url: string,
  zodSchema: T
): Promise<PaginationData<z.infer<T>>> {
  try {
    const response = await useAxios.get<z.infer<T>>(url)
    return {
      data: zodSchema.parse(response.data),
      pagy: pagy(response.headers),
    }
  } catch (error) {
    if (error instanceof z.ZodError) {
      console.error(error.issues)
    } else {
      console.log(error)
    }
    throw error
  }
}

export async function getZod<T extends z.ZodType<any, any, any>>(
  url: string,
  zodSchema: T
): Promise<z.infer<T>> {
  try {
    const response = await useAxios.get<z.infer<T>>(url)
    return zodSchema.parse(response.data)
  } catch (error) {
    if (error instanceof z.ZodError) {
      console.error(error.issues)
    } else {
      console.log(error)
    }
    throw error
  }
}

export async function get<T>(url: string): Promise<T> {
  try {
    const response = await useAxios.get<T>(url)
    return response.data
  } catch (error) {
    throw error
  }
}

export async function downloadFile(downloadUrl: string, defaultFilename: string): Promise<void> {
  try {
    const response = await useAxios.get(downloadUrl, {
      responseType: "blob",
    })

    // Extracting filename from Content-Disposition header
    const contentDisposition = response.headers["content-disposition"]
    let filename = defaultFilename
    if (contentDisposition) {
      const filenameRegex = /filename\*?="?([^;"]+)/
      const matches = filenameRegex.exec(contentDisposition)
      if (matches && matches[1]) {
        filename = decodeURIComponent(matches[1])
      }
    }

    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", filename)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  } catch (error) {
    throw error
  }
}

export async function post<T>(url: string, data: object | null = null): Promise<T> {
  try {
    const response = await useAxios.post<T>(url, data)
    return response.data
  } catch (error) {
    throw error
  }
}

export async function postFile<T>(url: string, file: File): Promise<T> {
  const formData = new FormData()
  formData.append("file", file)
  try {
    const response = await useAxios.post<T>(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    return response.data
  } catch (error) {
    throw error
  }
}

export async function postZod<T extends z.ZodType<any, any, any>>(
  url: string,
  data: object | null,
  zodSchema: T
): Promise<z.infer<T>> {
  try {
    const response = await useAxios.post<z.infer<T>>(url, data)
    return zodSchema.parse(response.data)
  } catch (error) {
    if (error instanceof z.ZodError) {
      console.error(error.issues)
    } else {
      console.log(error)
    }
    throw error
  }
}

export async function postFileZod<T extends z.ZodType<any, any, any>>(
  url: string,
  file: File,
  zodSchema: T
): Promise<z.infer<T>> {
  const formData = new FormData()
  formData.append("file", file)
  try {
    const response = await useAxios.post<z.infer<T>>(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    return zodSchema.parse(response.data)
  } catch (error) {
    if (error instanceof z.ZodError) {
      console.error(error.issues)
    } else {
      console.log(error)
    }
    throw error
  }
}

export async function patch<T>(url: string, data: Object): Promise<T> {
  try {
    const response = await useAxios.patch<T>(url, data)
    return response.data
  } catch (error) {
    throw error
  }
}

export async function patchZod<T extends z.ZodType<any, any, any>>(
  url: string,
  data: Object,
  zodSchema: T
): Promise<z.infer<T>> {
  try {
    const response = await useAxios.patch<z.infer<T>>(url, data)
    return zodSchema.parse(response.data)
  } catch (error) {
    if (error instanceof z.ZodError) {
      console.error(error.issues)
    } else {
      console.log(error)
    }
    throw error
  }
}

export async function del<T>(url: string): Promise<T> {
  try {
    const response = await useAxios.delete<T>(url)
    return response.data
  } catch (error) {
    throw error
  }
}

export async function delZod<T extends z.ZodType<any, any, any>>(
  url: string,
  zodSchema: T
): Promise<z.infer<T>> {
  try {
    const response = await useAxios.delete<z.infer<T>>(url)
    return zodSchema.parse(response.data)
  } catch (error) {
    if (error instanceof z.ZodError) {
      console.error(error.issues)
    } else {
      console.log(error)
    }
    throw error
  }
}

const isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}([+-]\d{2}:\d{2}|Z)$/;
const smallIsoDateFormat = /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])/;

function isIsoDateString(value: unknown): value is string {
  return !!value && typeof value === "string" && (isoDateFormat.test(value) || smallIsoDateFormat.test(value))
}

function isObject(value: unknown): value is Record<string, unknown> {
  return typeof value === "object" && value !== null
}

export function handleDates(body: unknown) {
  if (!isObject(body)) return

  const allowedKeys = ["_at", "time", "date", "trial", "period"]

  for (const key of Object.keys(body)) {
    const value = body[key]
    const isAllowed = allowedKeys.some((allowedKey) => key.includes(allowedKey))
    if (isIsoDateString(value)) {
      if (isAllowed) {
        body[key] = new Date(value)
      } else {
        console.error("Date key not allowed", key, value)
      }
    } else if (typeof value === "object") {
      handleDates(value)
    }
  }
}

useAxios.interceptors.response.use((originalResponse) => {
  handleDates(originalResponse.data)
  return originalResponse
})
