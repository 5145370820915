import type { RouteRecordRaw } from "vue-router"
import { RouterView } from "vue-router"
import { communityReadMeta } from "/js/router/meta"

export const communityMembersRouteNames = {
  members: "community_members",
  members_index: "community_members.index",
} as const

export const communityMembersRoutes: RouteRecordRaw[] = [
  {
    name: communityMembersRouteNames.members,
    path: "members",
    component: () => import("/js/components/Layout/SectionViews/FullSectionView.vue"),
    redirect: { name: communityMembersRouteNames.members_index },
    meta: communityReadMeta,
    children: [
      {
        path: "",
        name: communityMembersRouteNames.members_index,
        component: () => import("/js/components/Members/CommunityMembersList.vue"),
        meta: communityReadMeta,
      },
    ],
  },
]
