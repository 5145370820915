import type { BlockWidget } from "/js/components/BlockBuilder/Widget"
import { ref } from "vue"

const draggingElement = ref<BlockWidget | undefined>(undefined)

export const useDraggingElement = () => {
  const startDrag = (event: any) => {
    draggingElement.value = event.item._underlying_vm_
  }

  const endDrag = () => {
    draggingElement.value = undefined
  }

  return {
    draggingElement,
    startDrag,
    endDrag,
  }
}
