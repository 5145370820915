<template>
  <div class="flex h-full flex-col">
    <div
      class="flex items-center justify-between border-b border-gray-100 bg-slate-50 px-4 py-1.5 dark:border-ll-green-700 lg:hidden">
      <schools-nav class="h-8 w-8" />

      <button
        type="button"
        class="-mr-3 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900"
        @click="sidebarOpen = true">
        <span class="sr-only">Open sidebar</span>
        <Bars3Icon class="h-6 w-6" aria-hidden="true" />
      </button>
    </div>

    <mobile-navbar-transition no-transition v-model="sidebarOpen">
      <div class="flex h-full gap-2 rounded-r-lg bg-gray-50 p-2">
        <div class="flex h-full flex-col py-2">
          <small-sidebar-v2 />
        </div>
        <div class="z-10 rounded-lg bg-white shadow-lg" id="mobile-main-sidebar"></div>
      </div>
    </mobile-navbar-transition>

    <div class="flex min-h-0 flex-1 gap-2 p-2 lg:h-screen">
      <div class="hidden flex-col py-2 lg:flex overflow-y-auto scrollbar:h-0 scrollbar:w-0">
        <small-sidebar-v2 />
      </div>
      <div class="flex min-h-0 flex-1 flex-col">
        <div class="hidden items-center justify-end gap-4 pb-2 pr-2 lg:flex">
          <ProfileSidebarItems hide-titles />
        </div>
        <div class="flex min-h-0 flex-1">
          <slot name="main" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Bars3Icon } from "@heroicons/vue/20/solid"
import MobileNavbarTransition from "/js/components/Layout/MobileNavbarTransition.vue"
import SmallSidebarV2 from "/js/components/Layout/Sidebar/SmallSidebarV2.vue"
import SchoolsNav from "/js/components/Layout/SchoolsNav.vue"
import { useSidebarLayout } from "/js/components/Layout/useSidebarLayout"
import ProfileSidebarItems from "/js/components/Layout/Sidebar/ProfileSidebarItems.vue"

const { sidebarOpen } = useSidebarLayout()
</script>

<script lang="ts">
export default {
  name: "MainLayout",
}
</script>
