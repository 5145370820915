<template>
  <div class="flex flex-col">
    <WidgetLink
      v-for="item in widget.link_widget_items"
      class="flex items-center justify-between border-b p-4 hover:bg-gray-50 focus:bg-gray-100"
      :url="item.url"
      :navigationResource="item.linkable">
      <div class="flex flex-col">
        <span>{{ item.label }}</span>
        <span v-if="item.description" class="text-secondary text-sm">{{ item.description }}</span>
      </div>
      <ChevronRightIcon class="h-4 w-4" />
    </WidgetLink>
  </div>
</template>

<script setup lang="ts">
import { type LinksWidget } from "/js/components/BlockBuilder/Widget"
import WidgetLink from "/js/components/BlockBuilder/Cards/WidgetLink.vue"
import ChevronRightIcon from "/js/components/icons/ChevronRightIcon.vue"

const props = defineProps<{
  widget: LinksWidget
}>()
</script>

<script lang="ts">
export default {
  name: "LinksCard",
}
</script>
