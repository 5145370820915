<template>
  <accordion :items="widget.items" />
</template>

<script setup lang="ts">
import type { AccordionWidget } from "/js/components/BlockBuilder/Widget"
import Accordion from "/js/components/utilities/Accordion.vue"

defineProps<{
  widget: AccordionWidget
  raw?: boolean
}>()
</script>

<script lang="ts">
export default {
  name: "AccordionCard",
}
</script>
