<template>
  <user-modal :userId="option.id" #default="{ showModal }">
    <button class="flex items-center gap-4 w-full" @click="showModal">
      <round-avatar
        :avatar-url="option.avatar_url"
        :id="option.id"
        :verified="option.verified"
        :text="initials(option)"
        size="sm" />
      <div class="flex flex-col items-start">
        <div class="text-sm font-medium">{{ fullName(option) }}</div>
      </div>
    </button>
  </user-modal>

</template>

<script setup lang="ts">
import { fullName, initials } from "/js/models/User"
import RoundAvatar from "/js/components/RoundAvatar.vue"
import type { NavigationResourceUser } from "/js/components/NavigationResources/NavigationResourcesTypes"
import UserModal from "/js/components/Modals/UserModal.vue"

defineProps<{
  option: NavigationResourceUser
}>()
</script>

<script lang="ts">
export default {
  name: "UserOptionBox",
}
</script>
