import { z } from "zod"
import { del, getZod, patchZod, post, postZod } from "/js/composables/useAxios"
import { computed, onMounted, onUnmounted, ref, watch } from "vue"
import { type SocketMessage, useUserChannel } from "/js/composables/useUserChannel"
import { makeGetUrl } from "/js/composables/makeQueryString"
import type { Identifiable } from "/js/models/Identifiable"
import { useQueryClient } from "@tanstack/vue-query"

export const ZJobSearchDataQueryStatusTypeCompleted = z.literal("completed")
export const ZJobSearchDataQueryStatusTypeFailed = z.literal("failed")
export const ZJobSearchDataQueryStatusTypePending = z.literal("pending")

export const ZJobSearchDataQueryStatusType = z.enum([
  ZJobSearchDataQueryStatusTypeCompleted.value,
  ZJobSearchDataQueryStatusTypeFailed.value,
  ZJobSearchDataQueryStatusTypePending.value,
])

export const ZJobSearchDataQueryStatusModel = z.object({
  id: z.string(),
  status: ZJobSearchDataQueryStatusType,
})

export const ZJobPostBaseModel = z.object({
  id: z.string(),
  identifier: z.string(),
})

export const ZCoreSignalLinkedinJobPostAd = z.object({
  ...ZJobPostBaseModel.shape,
  applied: z.boolean(),
  bookmarked: z.boolean(),
  is_new: z.boolean(),
  identifier: z.string(),
  title: z.string().nullable(),
  url: z.string().nullable(),
  salary: z.string().nullable(),
  country: z.string().nullable(),
  job_created_at: z.date().nullable(),
  location: z.string().nullable(),
  seniority: z.string().nullable(),
  company_url: z.string().nullable(),
  description: z.string().nullable(),
  company_name: z.string().nullable(),
  external_url: z.string().nullable(),
  employment_type: z.string().nullable(),
  job_functions_collection: z.array(z.string()).nullable(),
  job_industries_collection: z.array(z.string()).nullable(),
})

export const ZCoreSignalLinkedinJobPostMemberExperience = z.object({
  title: z.string().nullable(),
  company_name: z.string().nullable(),
  company_url: z.string().nullable(),
  location: z.string().nullable(),
  date_from: z.string().nullable(),
  date_to: z.string().nullable(),
  duration: z.string().nullable(),
  order_in_profile: z.number().nullable(),
})

export const ZCoreSignalLinkedinJobPostMemberEducation = z.object({
  title: z.string().nullable(),
  subtitle: z.string().nullable(),
  date_from: z.string().nullable(),
  date_to: z.string().nullable(),
  description: z.string().nullable(),
})

export const ZCoreSignalLinkedinJobPostMember = z.object({
  ...ZJobPostBaseModel.shape,
  name: z.string().nullable(),
  first_name: z.string().nullable(),
  last_name: z.string().nullable(),
  title: z.string().nullable(),
  location: z.string().nullable(),
  industry: z.string().nullable(),
  connections: z.string().nullable(),
  canonical_url: z.string().nullable(),
  logo_url: z.string().nullable(),
  experience: ZCoreSignalLinkedinJobPostMemberExperience.array().nullable(),
  education: ZCoreSignalLinkedinJobPostMemberEducation.array().nullable(),
})

export const ZJobSearchFilters = z.object({
  created_at_gte: z.string().optional().nullable(),
  created_at_lte: z.string().optional().nullable(),
  last_updated_gte: z.string().optional().nullable(),
  last_updated_lte: z.string().optional().nullable(),
  title: z.string().optional().nullable(),
  keyword_description: z.string().optional().nullable(),
  employment_type: z.string().optional().nullable(),
  location: z.string().optional().nullable(),
  company_id: z.string().optional().nullable(),
  company_name: z.string().optional().nullable(),
  company_domain: z.string().optional().nullable(),
  company_exact_website: z.string().optional().nullable(),
  company_professional_network_url: z.string().optional().nullable(),
  deleted: z.string().optional().nullable(),
  application_active: z.string().optional().nullable(),
  country: z.string().optional().nullable(),
  industry: z.string().optional().nullable(),
})

export const ZJobSearchMemberFilters = z.object({
  name: z.string().optional().nullable(),
  title: z.string().optional().nullable(),
  location: z.string().optional().nullable(),
  industry: z.string().optional().nullable(),
  summary: z.string().optional().nullable(),
  created_at_gte: z.string().optional().nullable(),
  created_at_lte: z.string().optional().nullable(),
  last_updated_gte: z.string().optional().nullable(),
  last_updated_lte: z.string().optional().nullable(),
  deleted: z.string().optional().nullable(),
  country: z.string().optional().nullable(),
  skill: z.string().optional().nullable(),
  certification_name: z.string().optional().nullable(),
  experience_title: z.string().optional().nullable(),
  experience_company_name: z.string().optional().nullable(),
  experience_company_exact_name: z.string().optional().nullable(),
  experience_company_website_url: z.string().optional().nullable(),
  experience_company_website_exact_url: z.string().optional().nullable(),
  experience_company_linkedin_url: z.string().optional().nullable(),
  experience_company_industry: z.string().optional().nullable(),
  experience_company_size: z.string().optional().nullable(),
  experience_company_employees_count_gte: z.string().optional().nullable(),
  experience_company_employees_count_lte: z.string().optional().nullable(),
  experience_date_from: z.string().optional().nullable(),
  experience_date_to: z.string().optional().nullable(),
  experience_description: z.string().optional().nullable(),
  experience_deleted: z.string().optional().nullable(),
  experience_company_id: z.string().optional().nullable(),
  active_experience: z.string().optional().nullable(),
  keyword: z.string().optional().nullable(),
  education_institution_name: z.string().optional().nullable(),
  education_institution_exact_name: z.string().optional().nullable(),
  education_program_name: z.string().optional().nullable(),
  education_description: z.string().optional().nullable(),
  education_date_from: z.string().optional().nullable(),
  education_date_to: z.string().optional().nullable(),
  education_institution_linkedin_url: z.string().optional().nullable(),
})

export type JobSearchParams = z.infer<typeof ZJobSearchFilters>
export type JobSearchMemberParams = z.infer<typeof ZJobSearchMemberFilters>

export const JobSearchParamLabels: Record<keyof JobSearchParams, string> = {
  created_at_gte: "Created at after",
  created_at_lte: "Created at before",
  last_updated_gte: "Last updated after",
  last_updated_lte: "Last updated before",
  title: "Title",
  keyword_description: "Keywords",
  employment_type: "Employment Type",
  location: "Location",
  company_id: "Company ID",
  company_name: "Company Name",
  company_domain: "Company Domain",
  company_exact_website: "Company Exact Website",
  company_professional_network_url: "Company Professional Network URL",
  deleted: "Deleted",
  application_active: "Application active",
  country: "Country",
  industry: "Industry",
}

export const ZJobSearchMonitorMembershipModel = z.object({
  id: z.string(),
  job_search_monitor_id: z.string(),
  name: z.string(),
  new_jobs_count: z.number(),
  filters: ZJobSearchFilters.nullable(),
})

export type JobSearchMonitorMembership = z.infer<typeof ZJobSearchMonitorMembershipModel>

export type JobMonitorParams = {
  name?: string
  filters?: JobSearchParams
}

export const ZJobMonitorBadge = z.object({
  monitor_badge: z.boolean(),
})

export type JobMonitorBadge = z.infer<typeof ZJobMonitorBadge>
export type CoreSignalLinkedinJobPostAd = z.infer<typeof ZCoreSignalLinkedinJobPostAd>
export type CoreSignalLinkedinJobPostMember = z.infer<typeof ZCoreSignalLinkedinJobPostMember>
export type JobSearchDataQueryStatus = z.infer<typeof ZJobSearchDataQueryStatusModel>

export const JobSearchApi = {
  createJobSearch: async (
    filters: JobSearchParams,
    after_id?: string
  ): Promise<JobSearchDataQueryStatus> => {
    return await postZod(
      "/api/job_searches/CoreSignalLinkedinJobSearchAd",
      { job_search: { filters }, after_id },
      ZJobSearchDataQueryStatusModel
    )
  },

  // TODO: needs different params
  createJobMemberSearch: async (
    filters: JobSearchParams,
    after_id?: string
  ): Promise<JobSearchDataQueryStatus> => {
    return await postZod(
      "/api/job_searches/CoreSignalLinkedinJobSearchMember",
      { job_search: { filters }, after_id },
      ZJobSearchDataQueryStatusModel
    )
  },

  getJobPostAds: async (
    query_id: string,
    after_id?: string
  ): Promise<CoreSignalLinkedinJobPostAd[]> => {
    return await getZod(
      makeGetUrl(`/api/job_search_data_queries/${query_id}`, { after_id }),
      ZCoreSignalLinkedinJobPostAd.array()
    )
  },

  getJobPostMembers: async (
    query_id: string,
    after_id?: string
  ): Promise<CoreSignalLinkedinJobPostMember[]> => {
    return await getZod(
      makeGetUrl(`/api/job_search_data_queries/${query_id}`, { after_id }),
      ZCoreSignalLinkedinJobPostMember.array()
    )
  },

  getBookmarkedJobPostAds: async (before?: string): Promise<CoreSignalLinkedinJobPostAd[]> => {
    return await getZod(
      makeGetUrl(`/api/job_post_bookmarks`, { before }),
      ZCoreSignalLinkedinJobPostAd.array()
    )
  },

  bookmarkJobPost: async (jobPostId: string): Promise<void> => {
    await post(`/api/job_posts/${jobPostId}/job_post_bookmarks`, {})
  },

  unbookmarkJobPost: async (jobPostId: string): Promise<void> => {
    await del(`/api/job_posts/${jobPostId}/job_post_bookmarks`)
  },

  getAppliedJobPosts: async (before?: string): Promise<CoreSignalLinkedinJobPostAd[]> => {
    return await getZod(
      makeGetUrl(`/api/job_post_applications`, { before }),
      ZCoreSignalLinkedinJobPostAd.array()
    )
  },

  applyJobPost: async (jobPostId: string): Promise<void> => {
    await post(`/api/job_posts/${jobPostId}/job_post_applications`, {})
  },

  unapplyJobPost: async (jobPostId: string): Promise<void> => {
    await del(`/api/job_posts/${jobPostId}/job_post_applications`)
  },

  createMonitor: async (params: JobMonitorParams) => {
    return await postZod("/api/job_search_monitors", params, ZJobSearchMonitorMembershipModel)
  },

  updateMonitor: async (monitorId: string, params: JobMonitorParams) => {
    return await patchZod(
      `/api/job_search_monitors/${monitorId}`,
      params,
      ZJobSearchMonitorMembershipModel
    )
  },

  getMonitors: async (): Promise<JobSearchMonitorMembership[]> => {
    return await getZod("/api/job_search_monitors", ZJobSearchMonitorMembershipModel.array())
  },

  deleteMonitor: async (monitorId: string) => {
    await del(`/api/job_search_monitors/${monitorId}`)
  },

  getMonitorMembership: async (monitorId: string): Promise<JobSearchMonitorMembership> => {
    return await getZod(`/api/job_search_monitors/${monitorId}`, ZJobSearchMonitorMembershipModel)
  },

  getCountries: async (): Promise<string[]> => {
    return await getZod("/api/job_search_filters/countries", z.array(z.string()))
  },

  getIndustries: async (): Promise<string[]> => {
    return await getZod("/api/job_search_filters/industries", z.array(z.string()))
  },

  getPositions: async (): Promise<string[]> => {
    return await getZod("/api/job_search_filters/positions", z.array(z.string()))
  },

  getJobMonitorBadge: async (): Promise<JobMonitorBadge> =>
    await getZod("/api/job_search_monitors/badge", ZJobMonitorBadge),

  getJobPostsByParams: async (
    job_search: JobSearchParams,
    userChannel: ReturnType<typeof useUserChannel>,
    afterId?: string,
    timeoutAfter = 10000
  ): Promise<CoreSignalLinkedinJobPostAd[]> => {
    return await JobSearchApi.getJobPostsByParamsGeneric<
      JobSearchParams,
      CoreSignalLinkedinJobPostAd
    >(
      job_search,
      userChannel,
      JobSearchApi.createJobSearch,
      JobSearchApi.getJobPostAds,
      afterId,
      timeoutAfter
    )
  },

  getJobPostMembersByParams: async (
    job_search: JobSearchMemberParams,
    userChannel: ReturnType<typeof useUserChannel>,
    afterId?: string,
    timeoutAfter = 10000
  ): Promise<CoreSignalLinkedinJobPostMember[]> => {
    return await JobSearchApi.getJobPostsByParamsGeneric<
      JobSearchMemberParams,
      CoreSignalLinkedinJobPostMember
    >(
      job_search,
      userChannel,
      JobSearchApi.createJobMemberSearch,
      JobSearchApi.getJobPostMembers,
      afterId,
      timeoutAfter
    )
  },

  getJobPostsByParamsGeneric: async <JobSearchParamsType, JobPostType>(
    job_search: JobSearchParamsType,
    userChannel: ReturnType<typeof useUserChannel>,
    createJobSearch: (
      params: JobSearchParamsType,
      afterId?: string
    ) => Promise<JobSearchDataQueryStatus>,
    getJobPosts: (queryId: string, afterId?: string) => Promise<JobPostType[]>,
    afterId?: string,
    timeoutAfter = 10000
  ): Promise<JobPostType[]> => {
    let dataQueryId: string | undefined = undefined
    let resolver: (value: JobPostType[]) => void
    let rejecter: (reason?: any) => void
    let timeout: number

    const subscribeToNewMessageCallback = async (socketMessage: SocketMessage) => {
      if (
        socketMessage.type !== "job_search_data_query_completed" ||
        socketMessage.object.id !== dataQueryId
      ) {
        return // message type or ID doesn't match
      }

      // Stop the timeout when the socket event has been triggered
      clearTimeout(timeout)

      userChannel.unsubscribeFromNewMessage(subscribeToNewMessageCallback)

      try {
        const jobPosts = await getJobPosts(dataQueryId, afterId)
        resolver(jobPosts)
      } catch (error) {
        rejecter(error)
      }
    }

    userChannel.subscribeToNewMessage(subscribeToNewMessageCallback)

    const query = await createJobSearch(job_search, afterId)
    dataQueryId = query.id

    if (query.status === "completed") {
      userChannel.unsubscribeFromNewMessage(subscribeToNewMessageCallback)
      return await getJobPosts(query.id, afterId)
    }

    return new Promise<JobPostType[]>((resolve, reject) => {
      resolver = resolve
      rejecter = reject

      // Start the timeout only after we have subscribed and the socket is waiting for the event
      timeout = setTimeout(() => {
        userChannel.unsubscribeFromNewMessage(subscribeToNewMessageCallback) // Clean up the listener
        reject(new Error("Timeout: job search took too long"))
      }, timeoutAfter) // 10-second timeout
    })
  },
}

const badges = ref<Record<string, number>>({})

export const useJobMonitorBadge = () => {
  const queryClient = useQueryClient()

  const hasBadge = (membership: JobSearchMonitorMembership): boolean => {
    badges.value[membership.id] = membership.new_jobs_count
    return membership.new_jobs_count > 0
  }

  const anyBadge = computed(() => {
    return Object.values(badges.value).some((count) => count > 0)
  })

  const clearBadge = (membershipId: string) => {
    badges.value[membershipId] = 0
  }

  watch(anyBadge, (value) => {
    queryClient.setQueryData<JobMonitorBadge>(["job_monitor_badge"], { monitor_badge: value })
  })

  return {
    anyBadge,
    clearBadge,
    hasBadge,
  }
}

// TODO move the following / asses usage
export type ComboBoxItem = {
  id: string
  lowerCased: string
}

export const useComboBoxSearch = (list: string[]) => {
  const identifiableList = list.map((el): ComboBoxItem => {
    return {
      id: el,
      lowerCased: el.toLowerCase(),
    }
  })

  const query = ref("")

  const queryResults = computed((): ComboBoxItem[] => {
    if (!query.value || query.value.length === 0) {
      return identifiableList
    }

    const lowercaseQuery = query.value.toLowerCase()

    return identifiableList.filter((el) => el.lowerCased.includes(lowercaseQuery))
  })

  return {
    query,
    queryResults,
    identifiableList,
  }
}
