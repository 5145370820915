<template>
  <div class="card padding flex flex-col gap-4">
    <h6>Tasks</h6>

    <content-view :isError :isEmpty :isLoading>
      <template #error>
        <error-view title="Error loading tasks" :error action="Try Again" @on-action="refetch">
          <feed-empty-icon />
        </error-view>
      </template>
      <template #empty>
        <empty-view title="User hasn't created any tasks for this goal yet.">
          <feed-empty-icon />
        </empty-view>
      </template>
      <template #default>
        <div class="flex flex-col gap-4" v-auto-animate>
          <div v-for="task in tasks" class="flex gap-2 items-baseline text-sm">
            <input type="checkbox" :id="task.id" :checked="task.status === 'completed'" disabled />
            <span class="line-clamp-3" :title="task.description">{{ task.description }}</span>
          </div>
        </div>
      </template>
    </content-view>
  </div>
</template>

<script setup lang="ts">
import { GoalsApi } from "/js/services/GoalsApi"
import ErrorView from "/js/components/utilities/UI/ErrorView.vue"
import ContentView from "/js/components/utilities/UI/ContentView.vue"
import EmptyView from "/js/components/utilities/UI/EmptyView.vue"
import { computed, ref } from "vue"
import FeedEmptyIcon from "/js/components/icons/FeedEmptyIcon.vue"
import { useQuery } from "@tanstack/vue-query"

const props = defineProps<{
  goalId: string
}>()

const {
  data: tasks,
  isLoading,
  isError,
  isSuccess,
  refetch,
  error,
} = useQuery({
  queryKey: computed(() => ["publicGoalTasks", props.goalId]),
  queryFn: async () => await GoalsApi.getGoalTasks(props.goalId),
})

const isEmpty = computed(() => isSuccess && !tasks.value?.length)

</script>


<script lang="ts">
export default {
  name: "PublicUserGoalTasks",
}
</script>