import { z } from "zod"
import uniqBy from "lodash/uniqBy"

export const CommunityRoles = ["community_admins", "community_owners", "community_members"] as const
export const ProductRoles = ["product_managers", "product_instructors", "product_members"] as const

export const ZProductRole = z.enum(ProductRoles)
export const ZCommunityRole = z.enum(CommunityRoles)

export type ProductRole = (typeof ProductRoles)[number]
export type CommunityRole = (typeof CommunityRoles)[number]

export const CommunityOptionLabels: Record<CommunityRole, string> = {
  community_members: "Member",
  community_admins: "Admin",
  community_owners: "Owner",
}

export const ProductOptionLabels: Record<ProductRole, string> = {
  product_members: "Member",
  product_managers: "Manager",
  product_instructors: "Instructor",
}

export const roleOptionLabels: Record<UserGroupType, string> = {
  ...CommunityOptionLabels,
  ...ProductOptionLabels,
  custom: "Custom",
}

export const CommunityOptionTooltips: Record<CommunityRole, string> = {
  community_members: "Members can participate in your community and join Learning Hubs.",
  community_admins: "Admins can manage Learning Hubs but cannot change billing settings.",
  community_owners: "Owners can manage all aspects of the community, including billing settings."
}

export const ProductOptionTooltips: Record<ProductRole, string> = {
  product_members: "Members can Participate in a Learning Hub",
  product_managers: "Managers can manage all aspects of the Learning Hub, including pricing.",
  product_instructors: "Instructors can manage content and schedule events for the Learning Hub",
}

export const roleOptionTooltips: Record<UserGroupType, string> = {
  ...CommunityOptionTooltips,
  ...ProductOptionTooltips,
  custom: "Custom",
}

export const tagClass = (tag: UserGroupType): string => {
  switch (tag) {
    case "product_members":
      return "bg-red-100 text-red-500"
    case "product_managers":
      return "bg-sky-100 text-sky-500"
    case "product_instructors":
      return "bg-yellow-100 text-yellow-500"
    case "community_members":
      return "bg-red-100 text-red-500"
    case "community_admins":
      return "bg-teal-100 text-teal-500"
    case "community_owners":
      return "bg-indigo-100 text-indigo-500"
    case "custom":
      return "bg-gray-100 text-gray-500"
  }
}

export const UserGroupVisibilities = ["everyone", "admins"] as const
export type UserGroupVisibility = (typeof UserGroupVisibilities)[number]

const allGroupTypes = [...CommunityRoles, ...ProductRoles, "custom"] as const
export type UserGroupType = typeof allGroupTypes[number]

export const ZUserGroupWithType = z.object({
  id: z.string(),
  group_type: z.enum(allGroupTypes),
})

export const ZUserGroup = ZUserGroupWithType.extend({
  name: z.string(),
  product_id: z.string().nullable(),
  visibility: z.enum(UserGroupVisibilities),
  created_at: z.date(),
  users_count: z.number(),
})

export type UserGroup = z.infer<typeof ZUserGroup>
export type UserGroupWithType = z.infer<typeof ZUserGroupWithType>

export type GroupCategory = "community" | "product" | "custom"

export const groupCategory = (group: UserGroupWithType | UserGroupType): GroupCategory => {
  const groupType = typeof group === "string" ? group : group.group_type

  if (groupType === "custom") {
    return "custom"
  } else if (groupType.startsWith("community")) {
    return "community"
  } else {
    return "product"
  }
}

export const humanizedGroupCategory = (category: GroupCategory): string => {
  switch (category) {
    case "community":
      return "Community"
    case "product":
      return "Learning Hub"
    case "custom":
      return "Custom"
  }
}

export type GroupTypeUserGroups = Record<UserGroup["group_type"], UserGroupWithType[]>

export type CategoryUserGroups = Record<GroupCategory, GroupTypeUserGroups>

export const groupedRoles = (userGroups: UserGroupWithType[]) => {
  return userGroups.reduce((acc, group) => {
    const category = groupCategory(group)
    const groups = acc[category] ?? {}
    const groupType = group.group_type
    const groupList = groups[groupType] ?? []
    groups[groupType] = uniqBy([...groupList, group], "id")
    acc[category] = groups
    return acc
  }, {} as CategoryUserGroups)
}

export const isCommunityRole = (role: CommunityRole | ProductRole | "custom"): role is CommunityRole => {
  return CommunityRoles.includes(role as CommunityRole)
}