<template>
  <loading-view :loading="isLoading">
    <instructors-card :users="instructors" v-if="instructors && instructors.length > 0" />
    <empty-view title="No instructors found" v-else>
      <members-empty-icon class="h-40 w-40" />
    </empty-view>
    <error-message :error="error" v-if="error" />
  </loading-view>
</template>

<script setup lang="ts">
import type { InstructorsWidget } from "/js/components/BlockBuilder/Widget"
import { useQuery } from "@tanstack/vue-query"
import { computed } from "vue"
import { WidgetsApi } from "/js/services/WidgetsApi"
import ErrorMessage from "/js/components/ErrorMessage.vue"
import InstructorsCard from "/js/components/BlockBuilder/Cards/InstructorsCard.vue"
import MembersEmptyIcon from "/js/components/icons/MembersEmptyIcon.vue"
import EmptyView from "/js/components/utilities/UI/EmptyView.vue"
import LoadingView from "/js/components/LoadingView.vue"

const props = defineProps<{
  widgetId: string
  widget: InstructorsWidget
}>()

const {
  data: instructors,
  isLoading,
  error,
} = useQuery({
  queryKey: computed(() => ["widget_instructors", props.widgetId, props.widget]),
  queryFn: async () => await WidgetsApi.getInstructors(props.widgetId),
})
</script>

<script lang="ts">
export default {
  name: "ProductInstructorsCardLoader",
}
</script>
