import type { RouteRecordRaw } from "vue-router"
import { RouterView } from "vue-router"

export const profileRouteNames = {
  profile: "profile",
  profileEdit: "profile.edit",
  subscriptions: "profile.subscriptions",
  goalObjectives: "profile.goalObjectives",
  goalObjectivesIndex: "profile.goalObjectives.index",
  goalObjectiveShow: "profile.goalObjectiveShow",
  resume: "profile.resume",
}

export const profileRoutes: RouteRecordRaw[] = [
  {
    path: "",
    name: profileRouteNames.profileEdit,
    component: () => import("/js/components/Profile/ProfileEdit.vue"),
  },
  {
    path: "/subscriptions",
    name: profileRouteNames.subscriptions,
    component: () => import("/js/components/Profile/Subscriptions.vue"),
  },
  {
    path: "/resume",
    name: profileRouteNames.resume,
    component: () => import("/js/components/Profile/ProfileResumeEdit.vue"),
  },
  {
    name: profileRouteNames.goalObjectives,
    path: "/goals",
    component: RouterView,
    redirect: { name: profileRouteNames.goalObjectivesIndex },
    children: [
      {
        name: profileRouteNames.goalObjectivesIndex,
        path: "",
        component: () => import("/js/components/Profile/GoalObjectives.vue"),
      },
      {
        name: profileRouteNames.goalObjectiveShow,
        path: ":goalId",
        component: () => import("/js/components/Profile/GoalObjectiveShow.vue"),
        props: true,
      },
    ],
  }
]
