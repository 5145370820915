<template>
  <div class="flex flex-col divide-y divide-gray-100 rounded-lg border border-gray-100 bg-secondary">
    <slot v-bind="{ openItems, toggleCurrentItem }">
      <template v-if="items">
        <accordion-view
          v-for="item in items"
          :key="item.id"
          :item-open="openItems[item.id]"
          @toggle="toggleCurrentItem(item.id)">
          <template #title>{{ item.title }}</template>
          <template #default>
            <markdown-text :text="item.content" class="prose prose-sm" />
          </template>
        </accordion-view>
      </template>
    </slot>
  </div>
</template>

<script setup lang="ts">
import MarkdownText from "/js/components/utilities/MarkdownText.vue"
import { ref } from "vue"
import type { AccordionWidgetItem } from "/js/components/BlockBuilder/Widget"
import AccordionView from "/js/components/utilities/AccordionView.vue"

const props = withDefaults(
  defineProps<{
    items?: AccordionWidgetItem[]
    multi?: boolean
  }>(),
  {
    multi: false,
  }
)

const openItems = ref<Record<string, boolean>>({})

const toggleCurrentItem = (id: string) => {
  if (props.multi) {
    openItems.value[id] = !openItems.value[id]
  } else {
    openItems.value = {
      [id]: !openItems.value[id],
    }
  }
}
</script>

<script lang="ts">
export default {
  name: "Accordion",
}
</script>
