import { z } from "zod"

export type Community = {
  id: string,
  name: string,
  access: CommunityAccessType,
  domain: string,
  cover_url?: string,
  logo_url?: string,
  logo_thumb_url?: string,
  community_url: string
  trial: Date
  unread_notifications_count: number
}

export type CommunityAccessType = 'public_community' | 'private_community'

export type CommunityParams = {
  name: string,
  access: string,
  domain: string,
  cover?: string,
  logo?: string
}

export const CommunityFieldTypes = [
  "text_field",
  "single_select",
  "multi_select",
  "link_field"
] as const

export const CommunityFieldVisibility = [
  "public_field",
  "private_field"
] as const

export const ZCommunityFieldOption = z.object({
  id: z.string(),
  label: z.string()
})

export const ZCommunityFieldResponse = z.object({
  id: z.string(),
  community_field_id: z.string().optional(),
  field_name: z.string(),
  selected_options: z.string().array().nullable().optional(),
  response_text: z.string(),
})

export const ZCommunityField = z.object({
  id: z.string(),
  field_type: z.enum(CommunityFieldTypes),
  name: z.string(),
  description: z.string().nullable(),
  visibility: z.enum(CommunityFieldVisibility),
  position: z.number(),
  community_field_options: ZCommunityFieldOption.array().nullable().optional()
})

export const ZCommunityFieldGroup = z.object({
  id: z.string(),
  name: z.string(),
  community_fields: ZCommunityField.array().nullable()
})

export type CommunityField = z.infer<typeof ZCommunityField>
export type CommunityFieldGroup = z.infer<typeof ZCommunityFieldGroup>
export type CommunityFieldOption = z.infer<typeof ZCommunityFieldOption>
export type CommunityFieldResponse = z.infer<typeof ZCommunityFieldResponse>
export type CommunityFieldVisibility = typeof CommunityFieldVisibility[number]
export type CommunityFieldType = typeof CommunityFieldTypes[number]
export type CommunityFieldAndResponse = {
  field: CommunityField,
  response: CommunityFieldResponse | undefined
}

export type CommunityFieldGroupParams = {
  id?: string
  name: string
}

export type CommunityFieldParams = {
  id?: string
  name: string,
  description?: string | null,
  field_type: typeof CommunityFieldTypes[number],
  visibility: typeof CommunityFieldVisibility[number],
  position?: number,
  community_field_options_attributes?: CommunityFieldOptionParams[] | null
}

export type CommunityFieldOptionParams = {
  id?: string
  label: string,
  position?: number
  _destroy?: boolean
}
export type CommunityFieldOptionIdentifiableParams = CommunityFieldOptionParams & { identifier: string }

export type CommunityFieldResponseParams = {
  id?: string,
  field_name?: string,
  community_field_id?: string,
  selected_options?: string[] | null,
  response_text: string | null
}

export const CommunityFieldVisibilityLabels: Record<CommunityFieldVisibility, string> = {
  public_field: "Public",
  private_field: "Visible only to Admins",
}

export const CommunityFieldVisibilityTooltips: Record<CommunityFieldVisibility, string> = {
  public_field: "This field is visible to all community members and will show up on your public profile",
  private_field: "This field is only visible to community admins and will not show up on your public profile",
}

export const fieldVisibilityClass = (visibility: CommunityFieldVisibility): string => {
  switch (visibility) {
    case "public_field":
      return "bg-teal-100 text-teal-500"
    case "private_field":
      return "bg-yellow-100 text-yellow-500"
  }
}


export const CommunityFieldTypeLabels: Record<CommunityFieldType, string> = {
  text_field: "Text Field",
  single_select: "Single Select",
  multi_select: "Multi Select",
  link_field: "Link Field"
}