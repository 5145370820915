import type { BlockCardProps, BlockWidget } from "/js/components/BlockBuilder/Widget"

export const BlockCards = {
  CommunityBlockCard: {
    title: "Heading",
    description: "Highlight your community with a custom heading",
    bg: "bg-blue-400",
    blockType: "HeaderWidget",
  } as BlockCardProps,
  MembersBlockCard: {
    title: "Members",
    description: "Prompt social connections within your community",
    bg: "bg-yellow-400",
    blockType: "MembersWidget",
  } as BlockCardProps,
  FeaturedBlockCard: {
    title: "Featured Block",
    description: "Spotlight Learning Hubs from your community",
    bg: "bg-orange-400",
    blockType: "FeaturedProductsWidget",
  } as BlockCardProps,
  RichTextBlockCard: {
    title: "Rich Text",
    description: "Customize your content with our rich text editor",
    bg: "bg-red-400",
    blockType: "RichTextWidget",
  } as BlockCardProps,
  ContentBlockCard: {
    title: "Content",
    description: "Embed existing content from your community",
    bg: "bg-sky-300",
    blockType: "ContentWidget",
  } as BlockCardProps,
  EventsBlockCard: {
    title: "Events",
    description: "Highlight the most recent and next upcoming events",
    bg: "bg-purple-600",
    blockType: "EventWidget",
  } as BlockCardProps,
  FeedBlockCard: {
    title: "Feed",
    description: "Highlight the latest posts from across your community",
    bg: "bg-green-500",
    blockType: "FeedWidget",
  } as BlockCardProps,
  AccordionBlockCard: {
    title: "Accordion",
    description: "Create collapsible sections of content",
    bg: "bg-pink-500",
    blockType: "AccordionWidget",
  } as BlockCardProps,
  InstructorsBlockCard: {
    title: "Instructors",
    description: "Showcase your instructors",
    bg: "bg-blue-400",
    blockType: "InstructorsWidget",
  } as BlockCardProps,
  CurriculumBlockCard: {
    title: "Curriculum",
    description: "Highlight the curriculum for your Learning Hub",
    bg: "bg-yellow-400",
    blockType: "CurriculumWidget",
  } as BlockCardProps,
  ChatRoomBlockCard: {
    title: "Channels",
    description: "Connect with your community in real-time",
    bg: "bg-orange-400",
    blockType: "ChatRoomWidget",
  } as BlockCardProps,
  ColumnsBlockCard: {
    title: "Columns",
    description: "Create a multi-column layout",
    bg: "bg-amber-400",
    blockType: "ColumnsWidget",
  } as BlockCardProps,
  PhotoBlockCard: {
    title: "Photo",
    description: "Add a photo",
    bg: "bg-red-400",
    blockType: "PhotoWidget",
  } as BlockCardProps,
  LinksBlockCard: {
    title: "Links",
    description: "Add links to your content",
    bg: "bg-sky-300",
    blockType: "LinksWidget",
  } as BlockCardProps,
}

export const homeBlockCards: BlockCardProps[] = [
  BlockCards.CommunityBlockCard,
  BlockCards.MembersBlockCard,
  BlockCards.FeaturedBlockCard,
  BlockCards.RichTextBlockCard,
  BlockCards.ContentBlockCard,
  BlockCards.EventsBlockCard,
  BlockCards.FeedBlockCard,
  BlockCards.AccordionBlockCard,
  BlockCards.ChatRoomBlockCard,
]

export const hubOverviewBlockCards: BlockCardProps[] = [
  BlockCards.RichTextBlockCard,
  BlockCards.InstructorsBlockCard,
  BlockCards.AccordionBlockCard,
  BlockCards.CurriculumBlockCard,
  BlockCards.ChatRoomBlockCard,
]

export const communityRegistrationBlockCards: BlockCardProps[] = [
  BlockCards.CommunityBlockCard,
  BlockCards.RichTextBlockCard,
  BlockCards.AccordionBlockCard,
]

export const careerPageBlockCards: BlockCardProps[] = [
  BlockCards.CommunityBlockCard,
  BlockCards.RichTextBlockCard,
  BlockCards.ColumnsBlockCard,
  BlockCards.AccordionBlockCard,
  BlockCards.PhotoBlockCard,
  BlockCards.LinksBlockCard,
]

export const makeBlockWidgetCards = (blockCards: BlockCardProps[]): BlockWidget[] => {
  return blockCards.map(
    (c): BlockWidget => ({
      id: `block_${c.blockType}`,
      type: "block",
      data: c,
    })
  )
}
