<template>
  <div
    v-for="user in users"
    class="rounded-lg border border-gray-200 p-4">
    <user-modal :user-id="user?.id" #default="{ showModal }">
      <button @click="showModal" class="text-left flex flex-col gap-6">
        <div class="flex gap-4 items-center">
          <round-avatar
            :avatar-url="user.avatar_url"
            :id="user.id"
            :verified="user.verified"
            :text="initials(user)"
            size="sm2" />
          <div class="flex flex-col items-start">
            <div class="text-sm font-medium">{{ fullName(user) }}</div>
            <div class="text-secondary text-sm">Instructor</div>
          </div>
        </div>
        <div class="text-xs leading-5 whitespace-pre-line" v-if="user.description">{{ user.description }}</div>
      </button>
    </user-modal>
  </div>
</template>

<script setup lang="ts">
import { fullName, initials, type User } from "/js/models/User"
import RoundAvatar from "/js/components/RoundAvatar.vue"
import UserModal from "/js/components/Modals/UserModal.vue"

defineProps<{
  users: User[]
}>()
</script>

<script lang="ts">
export default {
  name: "InstructorsCard",
}
</script>
