<template>
  <tour-main />
  <route-user-modal/>
  <main-layout v-if="currentLayout === 'MainLayout'">
    <template #main>
      <router-view />
    </template>
    <template #rightside v-if="isRightSidebar">
      <right-sidebar>
        <router-view name="RightSidebar" />
      </right-sidebar>
    </template>
  </main-layout>

  <FullScreenLayout v-else-if="currentLayout === 'FullScreenLayout'">
    <router-view />
  </FullScreenLayout>
</template>

<script setup lang="ts">
import { useRoute } from "vue-router"
import { computed, ref, watch } from "vue"
import MainLayout from "/js/components/Layout/MainLayout.vue"
import RightSidebar from "/js/components/RightSidebar.vue"
import { closeUser, modalOpen } from "/js/composables/useModalUserProfile"
import { modalCourseOpen, closeCourse } from "/js/composables/useModalCourse"
import TourMain from "/js/components/Tour/TourMain.vue"
import FullScreenLayout from "/js/components/Layout/FullScreenLayout.vue"
import RouteUserModal from "/js/components/Modals/RouteUserModal.vue"

const route = useRoute()

const isRightSidebar = ref(false)

const currentLayout = computed(() => {
  if (route.meta.layout) {
    return route.meta.layout
  } else {
    return "MainLayout"
  }
})

watch(route, (from, to) => {
  if (to.matched.length) {
    isRightSidebar.value = !!to.matched[0]?.props.hasOwnProperty("RightSidebar")
  }

  if (modalOpen.value) {
    closeUser()
  }

  if (modalCourseOpen.value) {
    closeCourse()
  }
})
</script>

<script lang="ts">
export default {
  name: "App",
}
</script>
