<template>
  <div
    class="flex h-full w-full flex-col gap-4 overflow-auto"
    :class="[
      !widget?.disable_padding && 'p-4',
      !widget?.disable_card && 'card',
      horizontalMarginClass,
    ]">
    <slot name="title" v-if="!widget?.disable_title">
      <h4
        class="font-medium"
        :class="[widget?.enable_title_padding && 'px-4 pt-4']"
        v-if="widget.title && widget.title.length">
        {{ widget.title }}
      </h4>
    </slot>

    <slot />
  </div>
</template>

<script setup lang="ts">
import type { Widget } from "/js/components/BlockBuilder/Widget"
import { computed } from "vue"

const props = defineProps<{
  widget: Widget
}>()

const horizontalMarginClass = computed(() => {
  if (props.widget.parent_id) return undefined

  if (props.widget.card_horizontal_margin) {
    switch (props.widget.card_horizontal_margin) {
      case "sm":
        return "mx-auto max-w-screen-sm"
      case "md":
        return "mx-auto max-w-screen-md"
      case "lg":
        return "mx-auto max-w-screen-lg"
      case "xl":
        return "mx-auto max-w-screen-xl"
      case "2xl":
        return "mx-auto max-w-screen-2xl"
      case "3xl":
        return "mx-auto max-w-screen-3xl"
    }
  }

  return undefined
})

</script>

<script lang="ts">
export default {
  name: "WidgetCard",
}
</script>
