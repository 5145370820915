<template>
  <div class="relative overflow-auto">
    <div v-if="!elements.length" class="drop-zone-placeholder pointer-events-none absolute inset-4">
      {{ edit ? "Drag widgets here" : "No widgets configured" }}
    </div>

    <div v-if="edit">
      <draggable
        class="grid min-h-40 transform lg:grid-cols-12"
        :class="[!widget.no_gap && 'gap-4']"
        :list="elements"
        :animation="200"
        item-key="id"
        group="items"
        @start="startDrag($event)"
        @end="endDrag"
        filter=".btn"
        :disabled="draggableDisabled"
        @change="emit('draggableChanged', { event: $event, parentId: widget.id })"
        ghost-class="sidebar-ghost-columns">
        <template #item="{ element, index }">
          <div class="group relative" :class="[elementSizeClass(element)]">
            <div class="drop-zone-placeholder flex h-full flex-col justify-start">
              <block-widget-card
                @editElement="emit('editElement', $event)"
                @duplicateElement="emit('duplicateElement', $event)"
                @editedElement="emit('editedElement', $event)"
                @deleteElement="emit('deleteElement', $event)"
                :updateWidget
                :element
                :edit />
            </div>
          </div>
        </template>
      </draggable>
    </div>

    <template v-else>
      <paged-cards v-if="widget.carousel">
        <div
          v-for="element in elements"
          :key="element.id">
          <block-widget-card
            :element
            @editElement="emit('editElement', element)"
            @duplicateElement="emit('duplicateElement', element)"
            @deleteElement="emit('deleteElement', element)" />
        </div>
      </paged-cards>

      <div v-else class="grid min-h-40 lg:grid-cols-12" :class="[!widget.no_gap && 'gap-4']">
        <div
          v-for="element in elements"
          :key="element.id"
          :class="[elementSizeClass(element)]">
          <block-widget-card
            :element
            @editElement="emit('editElement', element)"
            @duplicateElement="emit('duplicateElement', element)"
            @deleteElement="emit('deleteElement', element)" />
        </div>
      </div>
    </template>

  </div>
</template>

<script setup lang="ts">
import draggable from "vuedraggable"
import {
  type BlockWidget,
  type BlockWidgetCardEvents,
  type ColumnSizeClass,
  type ColumnsWidget, getWidget,
  getWidgetType,
  type Widget,
  type WidgetParams,
} from "/js/components/BlockBuilder/Widget"
import BlockWidgetCard from "/js/components/BlockBuilder/Cards/BlockWidgetCard.vue"
import { computed, ref, watch } from "vue"
import { useDraggingElement } from "/js/components/BlockBuilder/useDraggingElement"
import PagedCards from "/js/components/Admin/Dashboard/PagedCards.vue"

const { draggingElement, endDrag, startDrag } = useDraggingElement()

const {
  edit = false,
  children,
  widget,
  updateWidget,
} = defineProps<{
  widget: ColumnsWidget
  children?: BlockWidget[]
  edit?: boolean
  updateWidget?: (widgetId: string, params: Partial<WidgetParams>) => Promise<Widget>
}>()

const sizeClass = (size: ColumnSizeClass | undefined) => {
  if (!size) return "lg:col-span-6"
  switch (size) {
    case "3":
      return "lg:col-span-3"
    case "4":
      return "lg:col-span-4"
    case "6":
      return "lg:col-span-6"
    case "8":
      return "lg:col-span-8"
    case "9":
      return "lg:col-span-9"
    case "12":
      return "lg:col-span-12"
  }
}

const elementColumnSizeClass = (blockWidget: BlockWidget): ColumnSizeClass => {
  const defaultSize = "6"
  const elementWidget = getWidget(blockWidget)
  if (!elementWidget) return defaultSize
  if (elementWidget.parent_id !== widget.id) return defaultSize

  const size = widget.column_sizes?.find((s) => s.id === elementWidget.id)
  return size?.size || defaultSize
}

const elementSizeClass = (blockWidget: BlockWidget) => {
  return sizeClass(elementColumnSizeClass(blockWidget))
}

const elements = ref<BlockWidget[]>([])

// const elementSizes = ref<Record<string, ColumnSize>>({})

// watch(
//   elements,
//   (newElements) => {
//     elementSizes.value = newElements.reduce(
//       (acc, element, index) => {
//         const size = widget.column_sizes?.find((s) => s.id === getWidgetId(element))
//         if (size) {
//           acc[getWidgetId(element)] = { ...size }
//         } else {
//           acc[getWidgetId(element)] = {
//             id: getWidgetId(element),
//             size: "6",
//           }
//         }
//         return acc
//       },
//       {} as Record<string, ColumnSize>
//     )
//   },
//   { immediate: true }
// )
//
// watch(
//   elementSizes,
//   async (newSizes, oldValue) => {
//     if (!edit) return
//     if (newSizes && Object.keys(newSizes).length === 0) return
//     if (!updateWidget) return
//     if (JSON.stringify(widget.column_sizes) === JSON.stringify(Object.values(newSizes))) return
//     console.log("updating widget", newSizes)
//     await updateWidget(widget.id, { column_sizes: Object.values(newSizes) })
//   },
//   { deep: true }
// )

const draggableDisabled = computed(() => {
  if (!edit) return true
  if (!draggingElement.value) return false
  return getWidgetType(draggingElement.value) === "ColumnsWidget"
})

watch(
  () => children,
  (newChildren) => {
    elements.value = newChildren || []
  },
  { immediate: true }
)

const emit = defineEmits<BlockWidgetCardEvents>()
</script>

<script lang="ts">
export default {
  name: "ColumnsCard",
}
</script>
