<template>
  <dropdown-menu-item class="grid grid-cols-4 gap-2" v-if="columnsWidget">
    <button
      v-for="size in ColumnSizeClasses"
      :key="size"
      @click.stop="handleColumnSizeChange(size)"
      class="btn btn-xs"
      :class="[currentSize === size ? 'btn-primary' : 'btn-outline']">
      {{ colSizeLabels[size] }}
    </button>
  </dropdown-menu-item>
</template>

<script setup lang="ts">
import DropdownMenuItem from "/js/components/DropdownMenuItem.vue"
import {
  type BlockWidget,
  type ColumnSizeClass,
  ColumnSizeClasses,
  getWidgetData,
  type Widget,
  type WidgetParams,
} from "/js/components/BlockBuilder/Widget"
import { computed } from "vue"
import { toastError } from "/js/composables/toast"

const props = defineProps<{
  parent: Widget
  widget: Widget
  children: BlockWidget[]
  updateWidget?: (widgetId: string, params: Partial<WidgetParams>) => Promise<Widget>
}>()

const emit = defineEmits<{
  edited: []
}>()

const columnsWidget = computed(() => getWidgetData(props.parent, "ColumnsWidget"))

const currentSize = computed(
  () => columnsWidget.value?.column_sizes?.find((size) => size.id === props.widget.id)?.size
)

const colSizeLabels: Record<ColumnSizeClass, string> = {
  "3": "25%",
  "4": "33%",
  "6": "50%",
  "8": "66%",
  "9": "75%",
  "12": "100%",
} as const

// Note that the click.stop in the template is to allow the emit to be registered in the parent
// the way this works, this sits in a dropdown, you click a button, by default the dropdown closes
// and the listener for the edited event gets lost
// when doing click.stop, the dropdown shouldn't close and the event should be emitted
// in our case, the dropdown will actually close since the emitted event triggers a full refresh

const handleColumnSizeChange = async (size: ColumnSizeClass) => {
  if (!props.updateWidget) return
  if (!columnsWidget.value) return

  try {
    await props.updateWidget(columnsWidget.value.id, {
      column_sizes: [
        ...(columnsWidget.value?.column_sizes || []).filter((s) => s.id !== props.widget.id),
        { id: props.widget.id, size },
      ],
    })

    emit("edited")
  } catch (e) {
    toastError(e)
  }
}
</script>

<script lang="ts">
export default {
  name: "ColumnCardChildDropdownOptions",
}
</script>
