<template>
  <user-modal :trigger="false" :userId v-model="showModalRef" />
</template>

<script setup lang="ts">
import { useRoute, useRouter } from "vue-router"
import { ref, watch } from "vue"
import UserModal from "/js/components/Modals/UserModal.vue"

const route = useRoute()
const router = useRouter()

const userId = ref<string | undefined>()
const showModalRef = ref(false)

watch(
  () => route.query,
  async () => {
    if (route.query.userId) {
      userId.value = route.query.userId as string
      showModalRef.value = true
    } else {
      showModalRef.value = false
    }
  },
  { immediate: true }
)

watch(
  () => showModalRef.value,
  async () => {
    if (!showModalRef.value && route.query.userId) {
      const { userId, ...queryWithoutUserId } = route.query
      await router.push({
        name: route.name,
        params: route.params,
        query: queryWithoutUserId,
      })
    }
  }
)

</script>

<script lang="ts">
export default {
  name: "RouteUserModal",
}
</script>
