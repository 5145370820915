<template>
  <loading-view :loading="isLoading">
    <error-message :error v-if="error" />
    <curriculum-card
      title="Curriculum"
      :productId="widget.product_id"
      :courseUnits
      v-else-if="courseUnits && courseUnits.length" />
    <empty-view
      class="!pb-0 !pt-12"
      title="No Curriculum Found"
      subtitle="Check back later for updates."
      v-else>
      <curriculum-empty-icon class="h-40 w-40" />
    </empty-view>
  </loading-view>
</template>

<script setup lang="ts">
import { type CurriculumWidget } from "/js/components/BlockBuilder/Widget"
import { useQuery } from "@tanstack/vue-query"
import { computed } from "vue"
import { WidgetsApi } from "/js/services/WidgetsApi"
import EmptyView from "/js/components/utilities/UI/EmptyView.vue"
import ErrorMessage from "/js/components/ErrorMessage.vue"
import CurriculumCard from "/js/components/BlockBuilder/Cards/CurriculumCard.vue"
import CurriculumEmptyIcon from "/js/components/icons/CurriculumEmptyIcon.vue"
import LoadingView from "/js/components/LoadingView.vue"

const props = defineProps<{
  widgetId: string
  widget: CurriculumWidget
}>()

const {
  data: courseUnits,
  isLoading,
  error,
} = useQuery({
  queryKey: computed(() => ["widget_course_unit", props.widgetId, props.widget]),
  queryFn: async () => await WidgetsApi.getCourseUnits(props.widgetId),
})
</script>

<script lang="ts">
export default {
  name: "CurriculumCardLoader",
}
</script>
