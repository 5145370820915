<template>
  <div class="flex flex-col gap-4">
    <div class="editor-minimal readonly" v-if="widget.body">
      <editor-wrapper :initial-data="widget.body" readonly v-if="widget.body" />
    </div>

    <WidgetLink
      class="btn btn-primary self-start"
      v-if="widget.show_cta"
      :url="widget.cta_url"
      :navigationResource="widget.cta_url_object">
      {{ widget.cta_label }}
    </WidgetLink>
  </div>
</template>

<script setup lang="ts">
import EditorWrapper from "/js/components/utilities/Editor/EditorWrapper.vue"
import type { RichTextWidget } from "/js/components/BlockBuilder/Widget"
import { computed } from "vue"
import type { RouteLocationNamedRaw } from "vue-router"
import { navigationResourceRoute } from "/js/components/NavigationResources/NavigationResourcesTypes"
import WidgetLink from "/js/components/BlockBuilder/Cards/WidgetLink.vue"

const props = defineProps<{
  widget: RichTextWidget
  noPadding?: boolean
}>()

const externalCtaUrl = computed(() => {
  if (props.widget.cta_url_type === "external" || !props.widget.cta_url_type) {
    return props.widget.cta_url
  }
  return undefined
})

const internalCtaRoute = computed((): RouteLocationNamedRaw | undefined => {
  if (props.widget.cta_url_type === "internal" && props.widget.cta_url_object) {
    return navigationResourceRoute(props.widget.cta_url_object)
  }
  return undefined
})
</script>

<script lang="ts">
export default {
  name: "RichTextCard",
}
</script>
