<template>
  <div
    v-if="communityError"
    class="flex h-screen w-screen flex-col items-center justify-center gap-4">
    <error-message :error="communityError" />
    <button class="btn-primary" @click="reloadData">Reload</button>
  </div>
  <loading-view v-else :loading="isLoading">
    <community-registration-overview :community class="min-h-screen bg-gray-50">
      <template #gradient-overlay>
        <div class="padding main-content-inset flex flex-col gap-4">
          <div class="flex justify-end" v-if="user">
            <dropdown-menu>
              <template #button>
                <round-avatar
                  :id="user.id"
                  :verified="user.verified"
                  :avatar-url="user.avatar_url"
                  :text="initials(user)" />
              </template>
              <template #default>
                <dropdown-menu-item @click="handleLogout">Log Out</dropdown-menu-item>
              </template>
            </dropdown-menu>
          </div>
        </div>
      </template>
      <template #left-actionbar>
        <!--  Community Name -->
        <div class="flex grow flex-col" v-if="community">
          <h4>{{ community.name }}</h4>
          <div class="text-xs">{{ communityAccessTitle }}</div>
        </div>
      </template>
      <template #right-actionbar>
        <div class="flex items-center gap-4">
          <div class="grow"></div>
          <!-- Join button / actions -->
          <button
            class="btn btn-primary"
            @click="handleMainAction"
            :disabled="mainActionLoading"
            v-if="currentMainAction !== 'restricted'">
            {{ mainActionText }}
          </button>

          <!-- Restricted -->
          <div v-else class="flex items-center gap-4">
            <lock-closed-icon class="h-10 w-10 text-teal-500" />
            <div>
              Access to this Community is restricted
              <div class="text-secondary text-xs">
                Only invited Members may join this community.
              </div>
            </div>
          </div>
        </div>
      </template>
    </community-registration-overview>

    <modal v-model="showRegistrationForm">
      <div class="px-8 pb-8" v-if="registrationType">
        <registration-container v-model="registrationType" />
      </div>
    </modal>

    <modal v-model="showModalPlanSelector" size-type="fullScreen">
      <loading-view :loading="subscriptionLoading">
        <plan-selector :subscription="subscription" />
      </loading-view>
    </modal>
  </loading-view>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue"
import LoadingView from "/js/components/LoadingView.vue"
import ErrorMessage from "/js/components/ErrorMessage.vue"
import { LockClosedIcon } from "@heroicons/vue/24/outline"
import { initials } from "/js/models/User"
import RoundAvatar from "/js/components/RoundAvatar.vue"
import DropdownMenu from "/js/components/DropdownMenu.vue"
import DropdownMenuItem from "/js/components/DropdownMenuItem.vue"
import { UsersApi } from "/js/services/UsersApi"
import { CommunitiesApi } from "/js/services/CommunitiesApi"
import { useCurrentUserService } from "/js/services/useCurrentUserService"
import { useCommunityService } from "/js/services/useCommunityService"
import Modal from "/js/components/Modals/Modal.vue"
import RegistrationContainer from "/js/components/Registration/RegistrationContainer.vue"
import type { RegistrationType } from "/js/components/Registration/RegistrationTypes"
import { useQuery } from "@tanstack/vue-query"
import { InvitationsApi } from "/js/services/InvitationsApi"
import { toastError } from "/js/composables/toast"
import { useGate } from "/js/composables/useGate"
import PlanSelector from "/js/components/Subscriptions/PlanSelector.vue"
import { PlansApi, subscriptionQueryKey } from "/js/services/PlansApi"
import CommunityRegistrationOverview from "/js/components/CommunityRegistration/CommunityRegistrationOverview.vue"

type MainActionType = "sign_in" | "join" | "restricted" | "subscribe" | "authorized"

const registrationType = ref<RegistrationType | null>(null)

const showRegistrationForm = ref(false)
const showModalPlanSelector = ref(false)

watch(registrationType, (value) => {
  if (value) {
    showRegistrationForm.value = true
  }
})

watch(showRegistrationForm, (value) => {
  if (!value) {
    // wait for transition before removing the registration type,
    // otherwise the modal content will disappear before the modal closes
    setTimeout(() => {
      registrationType.value = null
    }, 200)
  }
})

const currentUserService = useCurrentUserService()
const communityService = useCommunityService()

const isSignedIn = computed(() => !!window.APP_CONFIG.USER_ID)
const memberSubscriptionsEnabled = window.APP_CONFIG.MEMBER_SUBSCRIPTION

// user presence - signed in
const {
  data: user,
  refetch: refetchUser,
  isLoading: userLoading,
} = currentUserService.load(isSignedIn.value)

const { data: subscription, isLoading: subscriptionLoading } = useQuery({
  queryKey: subscriptionQueryKey,
  queryFn: async () => await PlansApi.getSubscription(),
  enabled: computed(() => memberSubscriptionsEnabled && isSignedIn.value),
})

// current community
const {
  data: community,
  error: communityError,
  refetch: refetchCommunity,
  isLoading: communityLoading,
} = communityService.loadCurrentCommunity()

// invitation presence - must be true if no memberships in the community and community private
const {
  data: invitation,
  refetch: refetchInvitation,
  isLoading: invitationLoading,
} = useQuery({
  queryKey: ["invitation", "Community", window.APP_CONFIG.COMMUNITY_ID],
  queryFn: async () => {
    const id = window.APP_CONFIG.COMMUNITY_ID
    if (id) {
      try {
        await InvitationsApi.getInvitationByInvitable("Community", id)
        return true
      } catch (e) {
        return false
      }
    } else {
      return false
    }
  },
  enabled: !!window.APP_CONFIG.COMMUNITY_ID,
})

const { communityGateStatus } = useGate()

const isLoading = computed(
  () =>
    userLoading.value ||
    communityLoading.value ||
    invitationLoading.value ||
    subscriptionLoading.value
)

const reloadData = () => {
  refetchUser()
  refetchCommunity()
  refetchInvitation()
}

const mainActionLoading = ref(false)

const currentCommunityStatus = computed(() => {
  return communityGateStatus("community_read")
})

const handleMainAction = async () => {
  if (mainActionLoading.value) {
    return
  }

  mainActionLoading.value = true

  switch (currentMainAction.value) {
    case "sign_in":
      registrationType.value = "signIn"
      break
    case "subscribe":
      showModalPlanSelector.value = true
      break
    case "join":
      try {
        await CommunitiesApi.joinCurrentCommunity()
        window.location.reload()
      } catch (e) {
        toastError(e)
      }
      break
    case "authorized":
      window.location.href = "/"
  }

  mainActionLoading.value = false
}

const handleLogout = async () => {
  await UsersApi.logout()
  window.location.reload()
}

const communityAccessTitle = computed(() => {
  if (community.value?.access === "public_community") {
    return "Public Community"
  } else {
    return "Private Community"
  }
})

const currentMainAction = computed((): MainActionType => {
  if (user.value) {
    if (currentCommunityStatus.value === "authorized") {
      return "authorized"
    } else if (currentCommunityStatus.value === "requires_subscription") {
      return "subscribe"
    } else if (currentCommunityStatus.value === "requires_permission") {
      if (community.value?.access === "public_community" || invitation.value) {
        return "join"
      } else {
        return "restricted"
      }
    } else {
      return "restricted"
    }
  } else {
    return "sign_in"
  }
})

const mainActionText = computed(() => {
  if (currentMainAction.value === "sign_in") {
    return "Sign in to join"
  } else if (currentMainAction.value === "join") {
    return "Join"
  } else if (currentMainAction.value === "subscribe") {
    return "Subscribe"
  } else if (currentMainAction.value === "authorized") {
    return "View Community"
  } else {
    return "Restricted"
  }
})
</script>

<script lang="ts">
export default {
  name: "CommunityRegistrationApp",
}
</script>
