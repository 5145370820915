<template>
  <div class="flex flex-col items-center gap-4">
    <content-view :is-error="isError" :is-empty="isSuccess && !user" :isLoading="isLoading">
      <template #empty>
        <empty-view title="User not found">
          <user-empty-icon />
        </empty-view>
      </template>
      <template #error>
        <error-view title="Something went wrong" action="Retry" @on-action="refetch">
          <user-empty-icon />
        </error-view>
      </template>
      <div class="flex w-full flex-col" v-if="user">
        <tab-layout :tabs default-value="tab_profile">
          <template #trigger="{ tab }">
            {{ userTabs[tab] ?? "" }}
          </template>
          <template #content="{ tab }">
            <div class="flex flex-col gap-4 py-6">
              <template v-if="tab === 'tab_profile'" v-memo>
                <public-user-profile-tab :user v-if="user" />
              </template>

              <template v-else-if="tab === 'tab_goals'">
                <div class="padding-x">
                  <div class="bg-primary padding rounded-lg">
                    <public-user-goals :userId />
                  </div>
                </div>
              </template>

              <template v-else-if="tab === 'tab_registrations'">
                <div class="padding-x">
                  <div class="bg-primary padding rounded-lg">
                    <public-user-registrations :userId />
                  </div>
                </div>
              </template>
            </div>
          </template>
        </tab-layout>
      </div>
    </content-view>
  </div>
</template>

<script setup lang="ts">
import { useQuery } from "@tanstack/vue-query"
import { UsersApi } from "/js/services/UsersApi"
import { computed } from "vue"
import ContentView from "/js/components/utilities/UI/ContentView.vue"
import EmptyView from "/js/components/utilities/UI/EmptyView.vue"
import UserEmptyIcon from "/js/components/icons/UserEmptyIcon.vue"
import ErrorView from "/js/components/utilities/UI/ErrorView.vue"
import TabLayout from "/js/components/Layout/TabLayout.vue"
import PublicUserGoals from "/js/components/Goals/PublicUserGoals/PublicUserGoals.vue"
import { useGate } from "/js/composables/useGate"
import PublicUserProfileTab from "/js/components/PublicUserProfile/PublicUserProfileTab.vue"
import PublicUserRegistrations from "/js/components/PublicUserProfile/PublicUserRegistrations.vue"

const UserTabs = ["tab_profile", "tab_goals", "tab_registrations"]
type UserTab = (typeof UserTabs)[number]

const props = defineProps<{
  userId: string
}>()

const { hasCommunityAdminAccess, hasAnyProductManagerAccess } = useGate()

const canViewRegistrations = computed(() => {
  return hasCommunityAdminAccess.value || hasAnyProductManagerAccess.value
})

const {
  data: user,
  isLoading,
  isSuccess,
  isError,
  refetch,
} = useQuery({
  queryKey: computed(() => ["user", props.userId]),
  queryFn: async () => {
    return await UsersApi.getUser(props.userId)
  },
  staleTime: Infinity
})

const userTabs: Record<UserTab, string> = {
  tab_profile: "Profile",
  tab_goals: "Goals",
  tab_registrations: "Registrations",
}

const tabs = computed(() => {
  if (canViewRegistrations.value) {
    return UserTabs
  } else {
    return UserTabs.filter((tab) => tab !== "tab_registrations")
  }
})
</script>

<script lang="ts">
export default {
  name: "UserModalContent",
}
</script>
