<template>
  <a :href="url" target="_blank" v-if="url">
    <slot/>
  </a>

  <router-link :to="internalRoute" v-else-if="internalRoute">
    <slot/>
  </router-link>
</template>

<script setup lang="ts">
import {
  type NavigationResource,
  navigationResourceRoute,
} from "/js/components/NavigationResources/NavigationResourcesTypes"
import { computed } from "vue"
import type { RouteLocationNamedRaw } from "vue-router"

const { linkClass = 'btn btn-primary', navigationResource } = defineProps<{
  linkClass?: string
  url?: string | null
  navigationResource?: NavigationResource | null
}>()

const internalRoute = computed((): RouteLocationNamedRaw | undefined => {
  if (navigationResource) {
    return navigationResourceRoute(navigationResource)
  }
  return undefined
})

</script>

<script lang="ts">
export default {
  name: "WidgetLink",
}
</script>