<template>
  <dropdown-menu-item class="flex items-center justify-between gap-4" v-if="photoWidget">
    <div class="text-secondary shrink-0 text-sm">Display Type</div>

    <div class="flex items-center justify-end gap-2">
      <button
        v-for="displayType in PhotoCardDisplayTypes"
        :key="displayType"
        @click.stop="handlePhotoCardDisplayType(displayType)"
        class="btn btn-xs"
        :class="[currentDisplayType === displayType ? 'btn-primary' : 'btn-outline']">
        {{ displayTypeLabels[displayType] }}
      </button>
    </div>
  </dropdown-menu-item>
</template>

<script setup lang="ts">
import DropdownMenuItem from "/js/components/DropdownMenuItem.vue"
import {
  PhotoCardDisplayTypes,
  type PhotoCardDisplayType,
  getWidgetData,
  type Widget,
  type WidgetParams,
} from "/js/components/BlockBuilder/Widget"
import { computed } from "vue"
import { toastError } from "/js/composables/toast"

const props = defineProps<{
  widget: Widget
  updateWidget?: (widgetId: string, params: Partial<WidgetParams>) => Promise<Widget>
}>()

const emit = defineEmits<{
  edited: []
}>()

const photoWidget = computed(() => getWidgetData(props.widget, "PhotoWidget"))

const currentDisplayType = computed(() => photoWidget.value?.display_type)

const displayTypeLabels: Record<PhotoCardDisplayType, string> = {
  contain: "Contain",
  cover: "Cover",
} as const

// Note that the click.stop in the template is to allow the emit to be registered in the parent
// the way this works, this sits in a dropdown, you click a button, by default the dropdown closes
// and the listener for the edited event gets lost
// when doing click.stop, the dropdown shouldn't close and the event should be emitted
// in our case, the dropdown will actually close since the emitted event triggers a full refresh

const handlePhotoCardDisplayType = async (size: PhotoCardDisplayType) => {
  if (!props.updateWidget) return
  if (!photoWidget.value) return

  try {
    await props.updateWidget(photoWidget.value.id, {
      display_type: size,
    })

    emit("edited")
  } catch (e) {
    toastError(e)
  }
}
</script>

<script lang="ts">
export default {
  name: "PhotoCardDropdownOptions",
}
</script>
