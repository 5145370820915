<template>
  <div class="padding-x flex flex-col gap-4">
    <gradient-image
      class="aspect-[21/7] max-h-64 w-full rounded-lg"
      :id="user.id"
      :url="user.cover_url" />

    <div class="-mt-32 px-8 pb-4 pt-8">
      <round-avatar
        class="border-2 border-white shadow"
        :id="user.id"
        :avatar-url="user.avatar_url"
        :verified="user.verified"
        :text="initials(user)"
        size="lg" />
    </div>

    <p class="flex items-center gap-4 text-lg font-medium text-gray-900">
      {{ fullName(user) }}

      <span
        v-if="communityRole"
        class="rounded-lg px-2 py-1 text-sm"
        :class="tagClass(communityRole.community_role)">
        {{ communityRoleLabel }}
      </span>

      <span class="flex-1"></span>

      <MessageUserAction
        v-if="canMessage"
        :userId="user.id"
        button-class="btn btn-primary btn-sm" />
    </p>

    <p class="text-secondary flex items-center gap-1 text-sm" v-if="timeZoneString">
      <ClockIcon class="h-4 w-4" />
      {{ timeZoneString }} local time
    </p>

    <p v-if="user.description" class="whitespace-pre-line text-xs text-gray-500">
      {{ user.description }}
    </p>

    <div
      class="space-y-4 rounded-xl border border-ll-slate-100 p-4"
      v-for="group in communityFieldGroups"
      :key="group.id">
      <div class="space-y-4">
        <div class="font-medium">{{ group.name }}</div>
        <div class="flex flex-col" v-for="field in group.community_fields">
          <span class="text-sm font-medium text-gray-900">{{ field.name }}</span>
          <span v-if="!responseByFieldId[field.id]?.response_text" class="text-sm text-gray-500"
            >No response received yet</span
          >
          <div v-if="responseByFieldId[field.id]">
            <a
              v-if="field.field_type === 'link_field'"
              :href="responseByFieldId[field.id]?.response_text"
              target="_blank"
              class="text-sm text-blue-500 underline">
              {{ responseByFieldId[field.id]?.response_text }}
            </a>
            <span v-else class="text-sm text-gray-500">{{ responseByFieldId[field.id]?.response_text }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { fullName, initials, type User } from "/js/models/User"
import { CommunityOptionLabels, tagClass } from "/js/services/permissionPolicyService"
import ClockIcon from "/js/components/icons/ClockIcon.vue"
import MessageUserAction from "/js/components/Chat/MessageUserAction.vue"
import RoundAvatar from "/js/components/RoundAvatar.vue"
import GradientImage from "/js/components/GradientImage.vue"
import { useCurrentUserService } from "/js/services/useCurrentUserService"
import { computed, ref, watch } from "vue"
import { useQuery } from "@tanstack/vue-query"
import { userCommunityFieldResponsesQueryKey, UsersApi } from "/js/services/UsersApi"
import type { CommunityFieldResponse } from "/js/models/Community"
import { communityFieldGroupsQueryKey, CommunityFieldsApi } from "/js/services/CommunityFieldsApi"

const props = defineProps<{
  user: User
}>()

const { data: currentUser } = useCurrentUserService().load()

const canMessage = computed(() => {
  if (!currentUser.value) return false
  return currentUser.value.id !== props.user.id
})

const { data: communityRole } = useQuery({
  queryKey: computed(() => ["communityRole", props.user.id]),
  queryFn: async () => await UsersApi.getCommunityRole(props.user.id),
  staleTime: Infinity,
})

const { data: communityFieldGroups } = useQuery({
  queryKey: computed(() => communityFieldGroupsQueryKey),
  queryFn: async () => await CommunityFieldsApi.getCommunityFieldGroups(),
  staleTime: Infinity,
})

const { data: communityFieldResponses } = useQuery({
  queryKey: computed(() => [userCommunityFieldResponsesQueryKey, props.user.id]),
  queryFn: async () => await UsersApi.getUserCommunityFieldsResponses(props.user.id),
  staleTime: Infinity,
})

const responseByFieldId = ref<Record<string, CommunityFieldResponse>>({})
watch(
  communityFieldResponses,
  () => {
    communityFieldResponses.value?.forEach((response) => {
      if (response.community_field_id) {
        responseByFieldId.value[response.community_field_id] = response
      }
    })
  },
  { immediate: true }
)

const communityRoleLabel = computed(() => {
  if (communityRole.value) {
    return CommunityOptionLabels[communityRole.value.community_role]
  }
  return undefined
})

const getTimeInUserTimezone = (timeZone: string) => {
  const date = new Date()

  const options: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    timeZoneName: "short",
    timeZone,
  }

  const formatter = new Intl.DateTimeFormat("en-US", options)
  return formatter.format(date)
}

const timeZoneString = computed(() => {
  if (props.user.time_zone) {
    return getTimeInUserTimezone(props.user.time_zone)
  }
  return undefined
})

const getFieldResponseText = (response: CommunityFieldResponse) => {
  return response.response_text ? response.response_text : "No response received yet"
}
</script>

<script lang="ts">
export default {
  name: "PublicUserProfileTab",
}
</script>
