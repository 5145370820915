<template>
  <div class="flex flex-1 flex-col items-center justify-between">
    <div class="flex h-full flex-col items-center gap-4">
      <schools-nav class="h-8 w-8" />

      <SidebarRouterLink title="Home" :to="{ name: 'learner_dashboard' }">
        <template #icon="{ isActive }">
          <DashboardFillIcon class="h-5 w-5" v-if="isActive" />
          <DashboardIcon class="h-5 w-5" v-else />
        </template>
      </SidebarRouterLink>

      <SidebarRouterLink title="Members" :to="{ name: communityMembersRouteNames.members }">
        <template #icon="{ isActive }">
          <Users2FillIcon class="h-5 w-5" v-if="isActive" />
          <Users2Icon class="h-5 w-5" v-else />
        </template>
      </SidebarRouterLink>

      <SidebarRouterLink
        title="Library"
        :to="{ name: communityContentRouteNames.community_content_index }">
        <template #icon="{ isActive }">
          <FolderFillIcon class="h-5 w-5" v-if="isActive" />
          <FolderIcon class="h-5 w-5" v-else />
        </template>
      </SidebarRouterLink>

      <SidebarRouterLink title="Learn" :to="{ name: 'learning_hubs.index' }">
        <template #icon="{ isActive }">
          <BookFillIcon class="h-5 w-5" v-if="isActive" />
          <BookIcon class="h-5 w-5" v-else />
        </template>
      </SidebarRouterLink>

      <SidebarRouterLink title="Career" :to="{ name: careerRouteNames.careerIndex }">
        <template #icon="{ isActive }">
          <span class="relative">
            <BriefcaseFillIcon class="h-5 w-5" v-if="isActive" />
            <BriefcaseIcon class="h-5 w-5" v-else />
            <unread-dot class="absolute -right-1 -top-1" v-if="hasBadge" />
          </span>
        </template>
      </SidebarRouterLink>

      <SidebarRouterLink title="Events" :to="{ name: 'community_events.index' }">
        <template #icon="{ isActive }">
          <CalendarFillIcon class="h-5 w-5" v-if="isActive" />
          <CalendarIcon class="h-5 w-5" v-else />
        </template>
      </SidebarRouterLink>

      <SidebarRouterLink title="Chat" :to="{ name: 'chat' }">
        <template #icon="{ isActive }">
          <ChatBubbleMultiFill class="h-5 w-5" v-if="isActive" />
          <ChatBubbleMulti class="h-5 w-5" v-else />
        </template>
      </SidebarRouterLink>

      <SidebarRouterLink title="Admin" :to="{ name: 'admin' }" v-if="hasCommunityAdminAccess">
        <template #icon="{ isActive }">
          <CogIconFill class="h-5 w-5" v-if="isActive" />
          <CogIcon class="h-5 w-5" v-else />
        </template>
      </SidebarRouterLink>

      <div class="flex-1"></div>

      <div class="flex flex-col items-center gap-4 lg:hidden">
        <ProfileSidebarItems />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import SchoolsNav from "/js/components/Layout/SchoolsNav.vue"
import CalendarFillIcon from "/js/components/icons/CalendarFillIcon.vue"
import CalendarIcon from "/js/components/icons/CalendarIcon.vue"
import BookFillIcon from "/js/components/icons/BookFillIcon.vue"
import BookIcon from "/js/components/icons/BookIcon.vue"
import ChatBubbleMultiFill from "/js/components/icons/ChatBubbleMultiFill.vue"
import ChatBubbleMulti from "/js/components/icons/ChatBubbleMulti.vue"
import CogIconFill from "/js/components/icons/CogIconFill.vue"
import CogIcon from "/js/components/icons/CogIcon.vue"
import { useGate } from "/js/composables/useGate"
import { computed, ref } from "vue"
import DashboardIcon from "/js/components/icons/DashboardIcon.vue"
import DashboardFillIcon from "/js/components/icons/DashboardFillIcon.vue"
import { BriefcaseIcon, FolderIcon } from "@heroicons/vue/24/outline"
import {
  BriefcaseIcon as BriefcaseFillIcon,
  FolderIcon as FolderFillIcon,
} from "@heroicons/vue/24/solid"
import { careerRouteNames } from "/js/router/CareerRoutes"
import { useQuery } from "@tanstack/vue-query"
import { JobSearchApi } from "/js/services/JobSearchApi"
import UnreadDot from "/js/components/icons/UnreadDot.vue"
import { communityMembersRouteNames } from "/js/router/MembersRoutes"
import Users2Icon from "/js/components/icons/Users2Icon.vue"
import Users2FillIcon from "/js/components/icons/Users2FillIcon.vue"
import { communityContentRouteNames } from "/js/router/CommunityContentRoutes"
import ProfileSidebarItems from "/js/components/Layout/Sidebar/ProfileSidebarItems.vue"
import Spinner from "/js/components/icons/Spinner.vue"
import SidebarRouterLink from "/js/components/Layout/Sidebar/SidebarRouterLink.vue"

const { hasCommunityAdminAccess } = useGate()

const { data: jobMonitorBadge } = useQuery({
  queryKey: ["job_monitor_badge"],
  queryFn: async () => await JobSearchApi.getJobMonitorBadge(),
})

const hasBadge = computed(() => !!jobMonitorBadge.value?.monitor_badge)
</script>

<script lang="ts">
export default {
  name: "SmallSidebarV2",
}
</script>
