import { z } from "zod"
import { ZResumeItem } from "/js/models/ResumeItem"

export const ZResume = z.object({
  id: z.string().optional().nullable(),
  summary: z.string().optional().nullable(),
  resume_items: z.array(ZResumeItem),
})

export type Resume = z.infer<typeof ZResume>

export const ZResumeImportWorkflow = z.object({
  workflow_id: z.string(),
})

export type ResumeImportWorkflow = z.infer<typeof ZResumeImportWorkflow>