import { getZod } from "/js/composables/useAxios"
import { type CommunityFieldGroup, ZCommunityFieldGroup } from "/js/models/Community"

export const communityFieldGroupsQueryKey = ["communityFieldGroups"]

export const CommunityFieldsApi = {
  getCommunityFieldGroups: async (): Promise<CommunityFieldGroup[]> => {
    return getZod("/api/community_field_groups", ZCommunityFieldGroup.array())
  },
}
